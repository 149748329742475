import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  desc: Yup.string().required('Articale body is required')
});

const BlogForm = ({ onSubmit, categories }) => {
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [value, setValue] = useState('');

  return (
    <Formik
      initialValues={{
        title: '',
        desc: '',
      }}
      onSubmit={async (values, { resetForm }) =>{
          const formData = new FormData()
          formData.append('upload_preset', 'larcossa')
          formData.append('file', image)
          
          await axios.post('https://api.cloudinary.com/v1_1/dhbqttdlc/image/upload', formData)
          .then(res=> {
			  values.image=res.data.secure_url
			  setPhoto(null)
			  })
          .catch(err=>console.log(err))
          values.article = value
            console.log(values)
          onSubmit(values, resetForm)
        }
      }
      validationSchema={BlogSchema}
      validateOnBlur={false}
    >
      {(formProps) => (
        <Form>
          <div className="flex flex-wrap justify-between ">
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Blog Title" />
              </div>
              <FormInput
                ariaLabel="Blog Title"
                name="title"
                type="text"
                placeholder="Blog Title"
              />
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Short Description" />
              </div>
              <Field
                className="border border-gray-300 rounded p-1 w-full h-10 mb-2"
                component="textarea"
                name="desc"
                type="text"
                placeholder="Short Description"
              />
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="The Blog Post" />
              </div>
              <div className="w-full lg:px-2 py-1">
                <ReactQuill theme="snow" value={value} onChange={setValue} className='px-2 mb-5 lg:px-8'/>
              </div>
            </div>	
            <div className="w-full px-2 py-5">
              <div className="mb-1">
                <Label text="Blog Image" />
              </div>
              <input
                className='w-full'
                ariaLabel="Image"
                name="image"
                type="file"
                placeholder="Image"
                onChange={(e)=>{
				  formProps.setFieldValue('image', e.target.files[0].name)	
                  setPhoto(URL.createObjectURL(e.target.files[0]))
				  setImage(e.target.files[0])
				  }}
              />
            </div>  
          </div>
          <div className="flex">
            <div className="w-full sm:w-1/4 mt-4">
              <GradientButton type="submit" text="Submit" />
            </div>
            <div className="w-full lg:w-1/2" >
              <img className="rounded-lg shadow-lg"  src={photo && photo}></img>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BlogForm;
