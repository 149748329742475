import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {publicFetch} from './../util/fetch';
import { BiEnvelope, BiMobile } from 'react-icons/bi';
import ContactForm from './../components/ContactForm';
import FormSuccess from '../components/FormSuccess';
import FormError from '../components/FormError';
import emailjs from 'emailjs-com';

const Contact = () => {
     const [email, setEmail] = useState('');
	 const [mobile, setMobile] = useState('');
	 const [successMessage, setSuccessMessage] = useState();
     const [errorMessage, setErrorMessage] = useState();
     
     useEffect(()=>{
        const getContactData = async() => {
            try {
              const { data } = await publicFetch.get(
                'contact'
              );
              const {email,mobile} = data[0];
              setMobile(mobile)
              setEmail(email);
            } catch (err) {
              console.log(err);
            }
        };
		getContactData();
	},[]);
	
	const onSubmit = (values, resetForm) => {

		emailjs.send('larcossapp','template_AgONpr6f_clone', {message_html: values.enquiry, from_name: values.name,reply_to: values.email, to_name: 'Larcossa', mobile: values.mobile}, 'user_20HQM9vLrkL9CYpK1quKT')
		.then((response) => {
				console.log(response)
				setSuccessMessage('Enquiry form Sent Successfully');
				setErrorMessage(null);
				resetForm();
		}, (err) => {
				setErrorMessage(err.message);
		});

	};

    return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Contact Us</title>
                <link rel="canonical" href="https://larcossa.com/contact" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10'>
                   <div className='py-5 px-5 lg:px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light'>Get in Touch</h2>
                   </div>
                  <div className='flex flex-wrap justify-between'> 

                    <div className='flex flex-wrap justify-center'>
                      <div className='w-full lg:w-2/3 md:px-5 lg:px-10 text-red-900'>
                      {successMessage && (
                                      <FormSuccess text={successMessage} />
                                      )}
                                      {errorMessage && <FormError text={errorMessage} />}
                        <ContactForm onSubmit={onSubmit}/>
                      </div>
                      <div className='w-full lg:w-1/3 px-2 lg:px-10'>
                        <div className='text-red-800'>
                          <BiEnvelope className='w-1/3 inline  text-pinkOther h-12 my-4'/> 
                        <a href={`mailto:${email}`}>{email}</a></div>
                          <div className='text-red-800'>
                          <BiMobile className='w-1/3 inline text-pinkOther h-12 my-4'/> <a href={`tel:${mobile}`}>{mobile}</a></div>
                      </div> 
                          
                      </div>

                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default Contact;
