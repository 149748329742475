import React, { useContext } from 'react';
import { CartContext } from './../../providers/cart/cart.provider'
import { ReactComponent as ShoppingIcon } from './../../images/shopping-bag.svg'
import './cart-icon.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShoppingBag} from '@fortawesome/free-solid-svg-icons';

const CartIcon = ({ itemCount, isOpen }) => {
  const { toggleHidden, cartItemsCount } = useContext(CartContext);
  return (
    <div className='cart-icon' onClick={toggleHidden}>
      {/* <ShoppingIcon className='shopping-icon' /> */}
      <FontAwesomeIcon icon={faShoppingBag} className='text-3xl text-pinkDark hover:text-pink-800 '/>
      <span className='item-count'>{cartItemsCount}</span>
    </div>
  );
};

export default CartIcon;
