import React, {useState, useContext}from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShoppingBag} from '@fortawesome/free-solid-svg-icons';
import logo from './../images/logo.png';
import CartIcon from './../components/cart-icon/cart-icon.component';
import CartDropdown from './../components/cart-dropdown/cart-dropdown.component';
import {CartContext} from './../providers/cart/cart.provider';
import { AuthContext } from './../context/AuthContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { hidden } = useContext(CartContext);
  const authContext = useContext(AuthContext)

  return(
//style={{background: 'linear-gradient(120deg, #fff 39%, #7b27ab 39%, #ff8b00 100% )'}}
  <header  className="bg-white navbar font-roboto fixed px-8 w-full shadow z-10 md:flex sm:justify-between md:items-center sm:px-16 sm:py-3">
    <div className="flex items-center justify-between px- py-3 sm:p-0">

      {/* <div className='bg-white p-2 lg:bg-opacity-0'>
        <Logo className="h-16 py-1 " alt=" />
      </div> */}
      <Link to='/'><img
        className="w-40 lg:w-48 xl:w-56 h-full"
        src={logo}
        alt="Logo"
      /></Link>
      
      <div className="md:hidden flex">
        <CartIcon />
        <button onClick={()=>setIsOpen(!isOpen)} type="button" className="block pinkdark hover:text-logoPuple focus:text-logoPuple focus:outline-none">
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
          {
            isOpen ? 
            <path  fillRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            :
            <path  fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          }
            
            
          </svg>
        </button>

      </div>

    </div>
    <nav className={`${isOpen ? 'block' : 'hidden'} px-2 pt-2 pb-4 md:flex sm:p-0 pinkdark items-center`}>

      <Link to="/" onClick={()=>setIsOpen(!isOpen)} className="block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:text-xs lg:text-sm uppercase sm:ml-1 lg:px-4 ">Home</Link>

      <Link to="/about-us" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4 ">About Us</Link>

      <Link to="/shop" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4 ">Shop</Link>

      <Link to="/makeup-services" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4 ">Makeup Services</Link>

      <Link to="/blog" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4 ">Blog</Link>

      <Link to="/contact" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4  ">Contact Us</Link>

      { authContext.isAuthenticated() && authContext.isUser() ? <Link to="/my-account" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4  ">My Account</Link>
      :<Link to="/login" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1 tracking-wide font-normal text-xs hover:text-red-800  sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm uppercase lg:px-4  ">Sign In</Link>}

      <span className='hidden md:block'><CartIcon onClick={()=>setIsOpen(!isOpen)}/></span>
      

      {/* <Link to="/shopping-bag" className={`relative flex px-3 items-center  ${isOpen ? 'hidden': 'justify-center'}`}>
        <div className='absolute pt-3 text-xs text-white'>10</div>
        <CartIcon className='text-3xl hover:text-red-800 '/>
        {hidden ? null : <CartDropdown />}
      </Link> */}
    
    </nav>
    {hidden ? null : <CartDropdown className=''/>}
  </header>
  
)};

export default Header;
