import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PageTitle from '../components/common/PageTitle';
import Card from '../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from '../context/FetchContext';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ManageShipping = () => {
const fetchContext = useContext(FetchContext);
const [successMessage, setSuccessMessage] = useState();
const [errorMessage, setErrorMessage] = useState();
const [shipping, setShipping] = useState('');
const [value, setValue] = useState('');


useEffect(() => {
  const getShipping = async () => {
    try {
      const { data } = await fetchContext.authAxios.get(
        'shipping'
      );
      const{shipping} = data[0]
      setValue(shipping);
      setShipping(data[0])
      
    } catch (err) {
      console.log(err);
    }
  };
  getShipping();
}, [fetchContext]);

const saveShipping = async shipping => {
  try {
    const { data } = await fetchContext.authAxios.patch(
      'shipping',
      shipping
    );
    setErrorMessage(null);
    setSuccessMessage(data.message);
  } catch (err) {
    const { data } = err.response;
    setSuccessMessage(null);
    setErrorMessage(data.message);
  }
};
return (
  <>
    <PageTitle title="Shipping Policy Info" />
    <Card>
      {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}
      <Formik
        initialValues={{
          
        }}
        onSubmit ={ async (values) => {
          values.shipping = value
          values.id = shipping._id
          saveShipping(values)
          }}
        enableReinitialize={true}
      >
        {() => (
          <Form>                
            <div className='py-2'>
                
                <div className="w-full lg:px-2 py-1">
              
                  <ReactQuill theme="snow" value={value} onChange={setValue} className=' px-2 pb-4 lg:px-8 '/> 
                </div>
              </div>
              
              <div className='m-2'>
                <GradientButton text="Save" type="submit" />
              </div>
          </Form>
        )}
      </Formik>
    </Card>
  </>
);
};

export default ManageShipping;
