import React, {useState, useEffect}from 'react';
import { FiFacebook, FiTruck, FiInstagram, FiShield} from 'react-icons/fi';
import { BiSupport } from "react-icons/bi";
import { AiOutlineTag, AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { Link } from 'react-router-dom';
import {publicFetch} from './../util/fetch'

//import { ReactComponent as LogoWhite } from '../../assets/logo-white.svg';

const TheFooter = () => {
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    
    useEffect(()=>{
       const getContactData = async() => {
           try {
             const { data } = await publicFetch.get(
               'contact'
             );
             const {email,mobile} = data[0];
             setMobile(mobile)
             setEmail(email);
           } catch (err) {
             console.log(err);
           }
       };
       getContactData();
   },[]);
    
    return (
    <>
    <div className='bg-dark2 container mx-auto font-roboto  py-6 sm:py-8 lg:py-10 px-4 sm:px-16 lg:px-20' 
          >
                <div className='flex flex-wrap leading-none'>
                     <div className='w-1/2 lg:w-1/4 px-2'>
                          <div className='flex justify-start items-center '>
                               <FiTruck className='w-1/4 lg:w-1/3 inline mx-1 text-pinkOther h-12 my-2 lg:my-4'/>
                               <p className='text-pinkOther text-sm sm:text-base'>Same day shipping within cbd</p>
                          </div>
                     </div>
                     <div className='w-1/2 lg:w-1/4 px-2'>
                          <div className='flex justify-start items-center'>
                               <AiOutlineTag className='w-1/4 lg:w-1/3 inline mx-1 text-pinkOther h-12 my-2 lg:my-4'/>
                               <p className='text-pinkOther text-sm sm:text-base'>Promotion Code</p>
                          </div>
                     </div>
                     <div className='w-1/2 lg:w-1/4 px-2'>
                          <div className='flex justify-start items-center'>
                               <BiSupport className='w-1/4 lg:w-1/3 inline mx-1 text-pinkOther h-12 my-2 lg:my-4'/>
                               <p className='text-pinkOther text-sm sm:text-base'>Support 24/7</p>
                          </div>
                     </div>
                     <div className='w-1/2 lg:w-1/4 px-2'>
                          <div className='flex justify-start items-center'>
                               <FiShield className='w-1/4 lg:w-1/3 inline mx-1 text-pinkOther h-12 my-2 lg:my-4'/>
                               <p className='text-pinkOther text-sm sm:text-base'>Order Protection</p>
                          </div>
                     </div>
      
                </div>
          </div>

    <div className=' font-roboto' style={{background: 'linear-gradient(90deg, #fff3f3 30%, #ffffff 100%)'}}>
        <div className='container mx-auto px-8  pt-10 sm:pt-10 sm:px-24'>
            <div className='flex flex-wrap pb-4 justify-between items-start'>
                
                <div className='w-full lg:w-1/5 pt-5'>
                    <h2 className='text-red-800 font-semibold'>FOLLOW US</h2>
                    <div className='py-5 text-sm text-red-900'>
                        <p className='text-xs'>
                            LET'S CONNECT ON SOCIAL 
                        </p>
                        <a href='https://facebook.com/larcossacosmetics' target='_blank' >
                                <AiOutlineFacebook className='w-10 inline mx-1 text-red-800 h-12 my-4'/>
                            </a>
                            <a href='https://instagram.com/larcossacosmetics' target='_blank' >
                                <AiOutlineInstagram className='w-10 inline mx-1 text-red-800 h-12 my-4'/>
                            </a>
                    </div>
                    
                </div>
                <div className='w-full lg:w-1/5 pt-5'>
                    <h2 className='text-red-800 font-semibold'>Need Help?</h2>
                    <div className='py-5 text-sm text-red-900'>
                        <Link to='/shipping-policy' className='hover:text-pinkOther'>Shipping Policy</Link><br/>
                        <Link to='/return-policy' className='hover:text-pinkOther'>Return Policy</Link><br/>
                        <Link to='/faqs' className='hover:text-pinkOther'>FAQs</Link>
                    </div>
                </div>
                <div className='w-full lg:w-1/5 pt-5'>
                    <h2 className='text-red-800 font-semibold'>Privacy & Terms</h2>
                    <div className='py-5 text-sm text-red-900'>
                        <Link to='/terms-of-use' className='hover:text-pinkOther'>Terms of Use</Link><br/>
                        <Link to='/privacy-policy' className='hover:text-pinkOther'>Privacy Policy</Link>
                    </div>
                    
                </div>
                
                <div className='w-full lg:w-1/5 pt-5'>
                    <h2 className='text-red-800 font-semibold'>Contact Info</h2>
                    <div className='py-5 text-sm text-red-900'>
                        <p>
                            Email: <a href={`mailto:${email}`}>{email}</a><br/>
                            Mobile: <a href={`tel:${mobile}`}>{mobile}</a>
                        </p>
                    </div>
                    
                </div>

                <div className='w-full lg:w-1/5 pt-5'>
                    <h2 className='text-red-800 font-semibold'> #LarcQueen</h2>
                    <div className='py-5 text-sm text-red-900'>
                        <p>
                        Shop With Us & Take Pics Of How Pretty You Look Using Our Products And Use Our Hashtag #LarcQueen To Get Featured.
                        </p>
                    </div>
                    
                </div>
            </div>
            <div className='w-full text-center py-4 px-8 sm:px-12 lg:px-20 border-t border-red-300'>
                    {/* <img src={require('../images/logo.png')} alt='logo'  className='w-11/12 pt-3'/> */}
                    <p className='text-red-900 text-sm py-4 pt-3'> &copy; 2023 Larcossa Cosmetics. All rights reserved.
                        <br/><a className='hidden pinkdark text-xs hover:text-red-900 ' href='https://evokemedia.co.ke' target='_blank'>Designed by Evoke Media.</a></p> 

            </div>
        </div>
    </div>
    </>

);
}
export default TheFooter;
