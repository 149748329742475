import React, {
  useState,
  useContext,
  useEffect
} from 'react';
import PageTitle from '../components/common/PageTitle';
import DashboardMetric from './../components/DashboardMetric';
import Card from '../components/common/Card';
import {
  faChartArea,
  faDollarSign,
  faUserPlus,
  faDollyFlatbed, faCheck, faCheckCircle, faCheckSquare, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FetchContext } from '../context/FetchContext';
import { formatCurrency, formatCurrency2  } from './../util';
import DashboardChart from './../components/DashboardChart';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';


const Dashboard = () => {
  const fetchContext = useContext(FetchContext);
  const [orders, setOrders] = useState([]);
  const [orderMarked, setOrderMarked] = useState([]);

  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();


  useEffect(() => {
    const getOrders = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          'orders'
        );
        setOrders(data);
      } catch (err) {
        console.log(err);
      }
    };

    getOrders();
  }, [fetchContext]);

  const markOrder = (order) =>{  
    const theOrder = {order}
    const markedOrder = async () =>{
      try{
	   const {data} = await fetchContext.authAxios.patch('orders', theOrder);
		setOrders(data.orders)
		setSuccessMessage(data.message);
      }catch(err){
	   console.log(err)
	   const { data } = err.response;
	  setErrorMessage(data.message);
      }
    }
    markedOrder();

  }

  const onDelete = async order => {

	try {
	  if (
	    window.confirm(
		 'Are you sure you want to delete this order '+order.reference+'?'
	    )
	  ) {
	    const {data} = await fetchContext.authAxios.delete(
		 `orders/${order._id}`
		);
		setOrders(
		 orders.filter(
		   order => order._id !== data.deletedOrder._id
		   )
		 );
		setSuccessMessage(data.message);
	  }
	} catch (err) {
	  const { data } = err.response;
	  setErrorMessage(data.message);
	}
   };

  return (
    <>
      <PageTitle title="Orders"/>
	 {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}
        <div className='bg-white px-5 hidden lg:block rounded-md shadow-md py-4'>
          <table className=" rounded table-auto  hidden lg:block text-red-800 text-xs text-left">
            <thead className='text-dark1'>
            <tr>
                  <th className="border-b border-pinkOther px-2 py-2">Date</th>
                  <th className="border-b border-pinkOther px-2 py-2">Order Ref.</th>
                  <th className="border-b border-pinkOther px-2 py-2">User/Place</th>
                  <th className="border-b border-pinkOther px-2 py-2">Items</th>
                  <th className="border-b border-pinkOther px-2 py-2">Fee</th>
			   <th className="border-b border-pinkOther px-2 py-2">Disc</th>
                  <th className="border-b border-pinkOther px-2 py-2">Total</th>
                  <th className="border-b border-pinkOther px-2 py-2"></th>
            </tr>
            </thead>
            <tbody >
            {orders.map((order, index)=>(
                  <tr key={order._id}>
                      <td className="border-b border-pink-200 px-2 py-2 text-dark1 font-semibold">
                       {moment(order.createdAt).format('D/M/YY')}<br/>
                        {moment(order.createdAt).format('h:mm a')}
                      </td>
                      <td className="border-b border-pink-200 px-2 py-2">{order.reference}</td>
					<td className="border-b border-pink-200 px-2 py-2 text-gray-900">
						<span className='text-red-800'>
							<span className="text-gray-800 font-semibold text-medium">{order.user.firstName} {order.user.lastName}</span> - 0{order.user.mobile}<br />
							{order.user.email} </span><br />
						{order.deliveryPlace}<br />
						<span className='text-red-700'>{order.destinationDetails}</span></td>
                      <td className="border-b border-pink-200 px-2 py-2 text-red-700 font-semibold">{order.items.map(item=>(
                            <div className='flex flex-row' key={item._id}>
						<div className=' w-full flex flex-row justify-between'>
							<span className='px-2'>{item.quantity}</span>
							<span className='px-2 justify-self-start'>{item.name}</span>  						
							<span className='px-2 '> {formatCurrency2(item.unitPrice-item.discountAmount)}
							</span> 
						</div>
                              <span className='px-2  text-red-900'> {formatCurrency2((item.unitPrice-item.discountAmount)*item.quantity)}
                              </span> 
                            </div>
                      ))}</td>
                     	 <td className="border-b border-pink-200 px-2 py-2">
                        		<span className='text-pink-600 float-right'> {order.deliveryFee}</span> 
                        </td>
				    <td className="border-b border-pink-200 px-2 py-2">
                        		<span className='text-gray-600 float-right'> -{order.discount}</span> 
                        </td>
                        <td className="border-b border-pink-200 px-2 py-2">
                        <span className=' font-bold text-dark2'> {formatCurrency2(order.total)}</span> 
                        </td>
                      <td className="border-b border-pink-200 px-2 py-2"> 
                        <div className='flex flex-row '>
				    {order.status?
                          <div className='text-green-600 px-2'>
                              <FontAwesomeIcon icon={faCheckSquare} className='text-xl'/>
                          </div>
                          :
                          <div className='text-gray-200 px-2'>
                              <FontAwesomeIcon icon={faCheckSquare} className='text-xl' />
                          </div>
                          }
					 <div className='text-pinkOther px-2'>
                              <FontAwesomeIcon icon={faTrashAlt} className='text-xl hover:text-red-600'  onClick={()=> onDelete(order)}/>
                          </div>
				</div>
                      </td>                
                  </tr>
            ))}
            </tbody>
          </table>    
        </div>
        <div className=' text-xs text-red-800 lg:hidden '>
            {
              orders.map((order, index)=>(
                	<div className='bg-white flex flex-col rounded shadow-md px-2 my-3'>
				<div className='border-b border-red-200 py-3 flex flex-row justify-between'>
					<div className='font-semibold text-dark1'>
                       			{moment(order.createdAt).format('h:mm a')} {moment(order.createdAt).format('D/M/YY')}
					</div>
					<div></div>
					
				</div>
				<div className='border-b border-red-200 py-3 flex flex-row justify-between'>
					<span className='font-semibold text-dark1'>ORDER REF.: </span>
					<span>{order.reference}</span>
				</div>
				<div className='border-b border-red-200 py-3 flex flex-col'>
					<div className='flex flex-row justify-between'>
						<span className='font-semibold text-dark1'>USER:</span>
						<span>{order.user.firstName} {order.user.lastName} <br/>{order.user.email} / 0{order.user.mobile} </span>
					</div>
					<div className='flex flex-row justify-between'>
						<span className='font-semibold text-dark1'>DELIVERY PLACE: </span>
						<span>{order.deliveryPlace} {order.destinationDetails}</span>
					</div>
				</div>
				<div className='border-b border-red-200 py-3 flex flex-col'>
					{order.items.map(item=>(
                           	<div className='flex flex-row' key={item._id}>
						<div className=' w-full flex flex-row justify-between'>
							<span className='px-2'>{item.quantity}</span>
							<span className='px-2 '>{item.name}</span>  
						
							<span className='px-2 '> {formatCurrency2(item.unitPrice-item.discountAmount)}
							</span> 
						</div>
                              	<span className='  text-red-900 font-semibold'> {formatCurrency2((item.unitPrice-item.discountAmount)*item.quantity)}
                              	</span> 
                            </div>
                      ))}
				</div>
				<div className='border-b border-red-200 py-3 flex flex-col'>
					<div className='flex flex-row justify-between'>
						<span className='font-semibold text-dark1'>DELIVERY FEE:</span>
						<span>{order.deliveryFee} </span>
						
					</div>
				</div>
				<div className='border-b border-red-200 py-3 flex flex-row justify-between'>
					<div className='font-semibold text-dark1'>
						DISCOUNT:
					</div>
					<div className=''>
						{order.discount}
					</div>
				</div>
				<div className='border-b border-red-200 py-3 flex flex-col'>
					<div className='flex flex-row justify-between'>
						<span className='font-semibold text-dark1'>TOTAL:</span>
						<span>{formatCurrency2(order.total)} </span>
						
					</div>
				</div>
				<div className=' py-3 flex flex-col'>
					<div className='flex flex-row justify-between'>
						{order.status?
						<div className='text-green-600 px-2'>
							<FontAwesomeIcon icon={faCheckSquare} className='text-xl ' />
						</div>
						:
						<div className='text-gray-200 px-2'>
							<FontAwesomeIcon icon={faCheckSquare} className='text-xl' />
						</div>
						}
						<div className='text-pinkOther px-2'>
						<FontAwesomeIcon icon={faTrashAlt} className='text-xl hover:text-red-600'  onClick={()=> onDelete(order)}/>
						</div>
					</div>
				</div>

                	</div>
              ))
            }
        </div>

      
    </>
  );
};

export default Dashboard;
