import React, { useState, useContext } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';

const ShopItemSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  desc: Yup.string().required('Description is required'),
  color: Yup.string().required('Color is required'),
  category: Yup.string().required('Category is required'),
  product: Yup.string().required('Product is required'),
  image: Yup.string().required('Image is required'),
  unitPrice: Yup.string().required('Unit price is required'),
});

const ShopItemForm = ({ onSubmit, categories, setFieldValue }) => {
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState(null);

  return (
    <Formik
      initialValues={{
        name: '',
        desc: '',
        color: '',
        category: '',
        product: '',
        image: '',
        unitPrice: '',
      }}
      onSubmit={(values, { resetForm }) => {
        const formData = new FormData();
        formData.append('upload_preset', 'larcossa');
        formData.append('file', image);
        axios
          .post(
            'https://api.cloudinary.com/v1_1/dhbqttdlc/image/upload',
            formData
          )
          .then((res) => {
            values.image = res.data.secure_url;
            setPhoto(null);
            onSubmit(values, resetForm);
          })
          .catch((err) => console.log(err));

        // onSubmit(values, resetForm);
      }}
      validationSchema={ShopItemSchema}
      validateOnBlur={false}
    >
      {(formProps) => (
        <Form>
          <div className="flex flex-wrap justify-between ">
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Name" />
              </div>
              <FormInput
                ariaLabel="Name"
                name="name"
                type="text"
                placeholder="Name"
              />
            </div>

            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Color" />
              </div>
              <FormInput
                ariaLabel="Color"
                name="color"
                type="text"
                placeholder="Color"
              />
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Category." />
              </div>
              <select
                name="category"
                className="border-gray-300 border rounded-md px-2 py-2"
                onChange={formProps.handleChange}
                value={formProps.values.category}
              >
                <option key="select" value="">
                  Select Category
                </option>
                {categories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Product" />
              </div>
              <FormInput
                ariaLabel="Product"
                name="product"
                type="text"
                placeholder="Product"
              />
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Description" />
              </div>
              <Field
                className="border border-gray-300 rounded p-1 w-full h-20 mb-2"
                component="textarea"
                name="desc"
                type="text"
                placeholder="Description"
              />
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Unit Price" />
              </div>
              <FormInput
                ariaLabel="Unit Price"
                name="unitPrice"
                type="number"
                placeholder="Unit Price"
              />
            </div>
            <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Image (Hint: 1920px X 1200px)" />
              </div>
              <input
                className="w-full"
                ariaLabel="Image"
                name="image"
                type="file"
                placeholder="Image"
                onChange={(e) => {
                  formProps.setFieldValue('image', e.target.files[0].name);
                  setPhoto(URL.createObjectURL(e.target.files[0]));
                  setImage(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-full sm:w-1/4 mt-4">
              <GradientButton type="submit" text="Submit" />
            </div>
            <div className="w-full lg:w-1/2">
              <img className="rounded-lg shadow-lg" src={photo && photo}></img>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShopItemForm;
