import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PageTitle from '../components/common/PageTitle';
import { FetchContext } from '../context/FetchContext';
import { formatCurrency } from '../util';
import SlideForm from '../components/SlideForm';
import DangerButton from '../components/common/DangerButton';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import FormInput from './../components/FormInput';
import Card from './../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form} from 'formik';
import Label from './../components/common/Label';


const SlideContainer = ({ children }) => (
<div className="bg-white rounded shadow-md mb-4 p-4">
  {children}
</div>
);

const Slide = ({ item, onDelete }) => {
  return (
    <div className="flex flex-col lg:flex-row px-2 lg:px-16 py-5 lg:py-10">
      <img
        className="rounded w-full lg:w-32 lg:h-full"
        src={item.image}
        alt="slide item"
      />
      <div className="flex flex-col lg:flex-row justify-between w-full ">
        <div className="flex flex-col lg:ml-4 justify-between">
          <div>
            <p className="font-bold text-xl text-gray-900">
              {item.caption?item.caption:'Image has no caption'}
            </p>
          </div>
        </div>
        <div className="lg:self-end py-2">
          <DangerButton
            text="Delete"
            onClick={() => onDelete(item)}
          />
        </div>
      </div>
    </div>
  );
};

const NewSlide = ({ onSubmit}) => {
return (
  <section className="bg-white p-4 shadow-md rounded-md">
    <p className="font-bold mb-2">New Slide</p>
    <SlideForm onSubmit={onSubmit} />
  </section>
);
};

const ManageSlide = () => {
const fetchContext = useContext(FetchContext);
const [slide, setSlide] = useState([]);
const [successMessage, setSuccessMessage] = useState();
const [errorMessage, setErrorMessage] = useState();

useEffect(() => {
  const getSlide = async () => {
    try {
      const { data } = await fetchContext.authAxios.get(
        'slide'
      );
      setSlide(data);
    } catch (err) {
      console.log('the err', err);
    }
  };

  getSlide();
}, [fetchContext]);

const onSubmit = async (values, resetForm) => {
  try {
    const { data } = await fetchContext.authAxios.post(
      'slide',
      values
    );
    setSlide([...slide, data.slide]);
    resetForm();
    setSuccessMessage(data.message);
    setErrorMessage(null);
  } catch (err) {
    const { data } = err.response;
    setSuccessMessage(null);
    setErrorMessage(data.message);
  }
};

const onDelete = async item => {
  try {
    if (
      window.confirm(
        'Are you sure you want to delete this Slide?'
      )
    ) {
      const {
        data
      } = await fetchContext.authAxios.delete(
        `slide/${item._id}`
      );
      setSlide(
        slide.filter(
          item => item._id !== data.deletedItem._id
        )
      );
    }
  } catch (err) {
    const { data } = err.response;
    setErrorMessage(data.message);
  }
};

return (
  <>
    <PageTitle title="Slides" />

    {successMessage && (
      <FormSuccess text={successMessage} />
    )}
    {errorMessage && <FormError text={errorMessage} />}

    <div className="mb-4">
      <NewSlide onSubmit={onSubmit} />
    </div>
    {slide && slide.length
      ? slide.map(item => (
          <SlideContainer key={item._id}>
            <Slide
              item={item}
              onDelete={onDelete}
            />
          </SlideContainer>
        ))
      : 'There are no Slides'}
  </>
);
};

export default ManageSlide;
