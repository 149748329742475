import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';

const ShopItemSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  // desc: Yup.string().required(
  //   'Description is required'
  // ),
  // color: Yup.string().required('Color is required'),
  // category: Yup.string().required('Category is required'),
  // product: Yup.string().required('Product is required'),
  // image: Yup.string().required('Image is required'),
  // unitPrice: Yup.string().required('Unit price is required')
});

const ShopItemForm2 = ({ onSubmit, data }) => {
	const [name, setName] = useState(data.name)
	const [color, setColor] = useState(data.color)
	const [desc, setDesc] = useState(data.desc)
	const [unitPrice, setUnitPrice] = useState(data.unitPrice)
	const [discountAmount, setDiscountAmount] = useState(data.discountAmount)
	const [discountCode, setDiscountCode] = useState(data.discountCode)
	const [codeAmount, setCodeAmount] = useState(data.codeAmount)


  return (
    <form className=' w-full lg:w-2/3' onSubmit={onSubmit}>
      <div className="flex flex-wrap justify-between">
        <div className="w-full px-2 py-1">
              <div className="mb-1">
                <Label text="Name" />
              </div>
					<input
							className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                name="name"
                type="text"
                value={name}
                onChange={e=>{setName(e.target.value)}}
              />
				</div>

				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Color" />
					</div>
					<input
						className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						name="color"
						type="text"
						value={color}
            onChange={e=>{setColor(e.target.value)}}
					/>
				</div>
				
				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Description" />
					</div>
					<input
						className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						name="desc"
						type="text"
						value={desc}
            onChange={e=>{setDesc(e.target.value)}}
					/>
				</div>
				
				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Unit Price" />
					</div>
					<input
						className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						name="unitPrice"
						type="number"
						value={unitPrice}
            onChange={e=>{setUnitPrice(e.target.value)}}
					/>
				</div>
				
				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Sales Discount" />
					</div>
					<input
						className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						name="discountAmount"
						type="number"
						value={discountAmount}
            onChange={e=>{setDiscountAmount(e.target.value)}}
					/>
				</div>
				
				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Coupon Code" />
					</div>
					<input
						className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						name="discountCode"
						type="text"
						value={discountCode}
            onChange={e=>{setDiscountCode(e.target.value)}}
					/>
				</div>
				
				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Coupon Amount" />
					</div>
					<input
						className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						name="codeAmount"
						type="number"
						value={codeAmount}
            onChange={e=>{setCodeAmount(e.target.value)}}
					/>
				</div>
				
				<div className="w-full px-2 py-1">
					<div className="mb-1">
						<Label text="Sold Out" />
					</div>
					<input className="mr-2 leading-tight" type="checkbox" defaultChecked={data.soldOut}
								name="soldOut"/>
				</div>

			</div>  
			<div className="flex">
				<div className="w-full sm:w-1/4 mt-4">
					<GradientButton type="submit" text="Submit" />
				</div>
			</div>
    </form>
  );
};

export default ShopItemForm2;
