export const formatCurrency = num => {
  return `Ksh ${num
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatCurrency2 = num => {
  return `${num
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};
