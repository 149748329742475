import React, {
    useContext,
    useState,
    useEffect
  } from 'react';
import PageTitle from '../components/common/PageTitle';
import { Link, useLocation } from 'react-router-dom';
import Card from './../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from './../context/FetchContext';
import FormError from './../components/FormError';
import FormSuccess from './../components/FormSuccess';
import Writeup from './../components/writeup'

const WriteUps = ({ text }) => {
return (
    <>
        
        <div>
            <Writeup />
        </div>
    </>
)
}
export default WriteUps;