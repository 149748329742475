import React, { useContext,useState } from 'react';
import CheckoutItem from './../../components/checkout-item/checkout-item.component';
import { CartContext } from './../../providers/cart/cart.provider';
import GradientButton from './../../components/common/GradientButton';
import './checkout.styles.scss';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {Link} from 'react-router-dom'
import Label from './../../components/common/Label';
import FormInput from './../../components/FormInput';
import FormError from './../../components/FormError';
import FormSuccess from './../../components/FormSuccess';
import {publicFetch} from './../../util/fetch';
import { Redirect } from 'react-router-dom';
import { AuthContext } from './../../context/AuthContext';
import {Helmet} from "react-helmet";

const SignupSchema = Yup.object().shape({
	firstName: Yup.string().required(
	  'First name is required'
	),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string()
	  .email('Invalid email')
	  .required('Email is required'),
	mobile: Yup.number().min(10).required('Mobile number is required'),
	password: Yup.string().required('Password is required')
});

const CheckoutPage = () => {
  const { cartItems, cartTotal } = useContext(CartContext);
  const cartContext = useContext(CartContext);
  const authContext = useContext(AuthContext);
  const [signupSuccess, setSignupSuccess] = useState();
  const [signupError, setSignupError] = useState();
  const [loginLoading, setLoginLoading] = useState(false);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);

  const submitCredentials = async credentials => {
    try {
      setLoginLoading(true);
      const {data} = await publicFetch.post('signup', credentials);

      setSignupSuccess(data.message);
      setSignupSuccess('');
      setTimeout(()=>{setRedirectOnLogin(true)}, 700); 

      authContext.setAuthState(data)
               
    } catch (error) {
      setLoginLoading(false);
      const { data } = error.response;
      setSignupError(data.message);
      setSignupSuccess('');
    }
  };

  return (
    <>
		<Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - My Account</title>
                <link rel="canonical" href="https://larcossa.com/checkout" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
	 {redirectOnLogin && <Redirect to="/my-account" /> }
		<div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10'>
                   <div className='py-5 px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light py-3'>{authContext.isAuthenticated()?'Checkout':'Create Account' }</h2>
                   </div>
                    <div className='flex flex-col lg:flex-row justify-center'>
						<div className={`w-full ${authContext.isAuthenticated()? 'lg:w-4/5':null} lg:px-2 mb-3 lg:mx-2 bg-white lg:rounded lg:shadow-md`}>

							<div className='px-5 lg:px-5 py-5'>

							<div className='flex justify-between border-b pb-3 border-red-500 w-full text-dark1 '>
									<div className='header-block'>
										<span>Product</span>
									</div>
									<div className='header-block'>
										<span>Description</span>
									</div>
									<div className='header-block'>
										<span>Quantity</span>
									</div>
									<div className='header-block'>
										<span>Price</span>
									</div>
									<div className='header-block'>
										<span>Remove</span>
									</div>
								</div>
								{cartItems.map(cartItem => (
									<CheckoutItem key={cartItem._id} cartItem={cartItem} />

								))}
								
								
							</div>
									<div className=' w-full lg:w-2/3 flex flex-col lg:pt-2 px-5 lg:px-5'>
                                        {/* {
                                             cartContext.deliveryFee>0
                                             ?<div className=' w-full text-red-800'>
                                                  <div className='flex items-end' >
                                                       <div className=' w-full lg:w-5/6 float-right'>
                                                            <p className='lg:float-right'>{cartContext.deliveryPlace}</p> </div>
                                                       <div className=' text-lg w-full lg:w-1/6'>
                                                            <p className='float-right'>Ksh {cartContext.deliveryFee}</p></div>
                                                  </div>
                                             </div>
                                             :null
                                        } */}
                                             <div className=' w-full text-lg leading-8'>
                                                  {cartContext.discountAmount?<span className=' w-full'>
                                                       <p className='w-full float-right py-1'>Discount: Ksh {cartContext.discountAmount}</p></span>:null}
                                                  <span className=' w-full float-right lg:text-xl text-dark1'>
                                                       <p className=' float-right py-1'>SUBTOTAL: Ksh {cartContext.subTotal}</p></span>
                                             </div>
                                        </div>
										{/* <p className='italic font-playFair text-lg text-pinkOther pt-4 px-5 lg:px-3'>All payments are secure and encrypted</p> */}
							<div className='py-5 pb-20 px-5'>
								{cartTotal>0&&authContext.isAuthenticated()?<Link to='/my-account' className='text-white shadow-md rounded-full bg-pinkDark hover:bg-pinkLight py-2 px-8 float-right'>CHECKOUT</Link>:null}
							</div>


						</div> 

						{authContext.isAuthenticated()?null:<div className='w-full lg:w-1/2 px-10 text-red-900 bg-white rounded shadow-md lg:mx-2'>
							<div className=''>
								<h2 className='text-2xl text-dark1 pt-5'>Account Info</h2>
								<Formik
									initialValues={{
									firstName: '',
									lastName: '',
									email: '',
									password: '',
									mobile: '',
									}}
									onSubmit={values =>
									submitCredentials(values)
									}
									validationSchema={SignupSchema}
								>
									{() => (
									<Form className="py-3 pb-5">
										{signupSuccess && (
										<FormSuccess text={signupSuccess} />
										)}
										{signupError && (
										<FormError text={signupError} />
										)}
										<input
										type="hidden"
										name="remember"
										value="true"
										/>
										<div>
										<div className="flex">
											<div className="mb-2 mr-2 w-1/2">
											<div className="mb-1">
												<Label text="First Name" />
											</div>
											<FormInput
												ariaLabel="First Name"
												name="firstName"
												type="text"
												placeholder="First Name"
											/>
											</div>
											<div className="mb-2 ml-2 w-1/2">
											<div className="mb-1">
												<Label text="Last Name" />
											</div>
											<FormInput
												ariaLabel="Last Name"
												name="lastName"
												type="text"
												placeholder="Last Name"
											/>
											</div>
										</div>
										<div className="mb-2">
											<div className="mb-1">
											<Label text="Mobile Number" />
											</div>
											<FormInput
											ariaLabel="Mobile Number"
											name="mobile"
											type="number"
											placeholder="Mobile Number"
											/>
										</div>
										<div className="mb-2">
											<div className="mb-1">
											<Label text="Email address" />
											</div>
											<FormInput
											ariaLabel="Email address"
											name="email"
											type="email"
											placeholder="Email address"
											/>
										</div>
										
										<div>
											<div className="mb-1">
											<Label text="Password" />
											</div>
											<FormInput
											ariaLabel="Password"
											name="password"
											type="password"
											placeholder="Password"
											/>
										</div>
										</div>

										<div className="mt-6">
										<GradientButton
											type="submit"
											text="Checkout"
											loading={loginLoading}
										/>
										
										</div>
									</Form>
									)}
								</Formik>
								
							</div>
						</div>}

                    </div>
                </div>

          </div>
	</>
  );
};

export default CheckoutPage;
