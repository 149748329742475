import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Flickity from 'react-flickity-component';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { publicFetch } from './../util/fetch';
import CollectionItem from './../components/collection-item/collection-item.component';
import GradientButton from '../components/common/GradientButton';
import Label from './../components/common/Label';
import FormInput from './../components/FormInput';
import FormSuccess from './../components/FormSuccess';
import FormError from './../components/FormError';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

const flickityOptions = {
  initialIndex: 0,
  fade: true,
  pauseAutoPlayOnHover: false,
  prevNextButtons: true,
  autoPlay: 4000,
  wrapAround: true,
  fullscreen: true,
  adaptiveHeight: true,
  pageDots: false,
  imagesLoaded: true,
};

const EmailSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
});

const Home = () => {
  const [collections, setCollections] = useState([]);
  const [slides, setSlides] = useState([]);
  const accepted = localStorage.getItem('cookiee')
    ? localStorage.getItem('cookiee')
    : false;
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();

  //console.log(new Date().getTime())
  //console.log(new Date().getTime()/1000)

  useEffect(() => {
    const getCollectionsData = async () => {
      try {
        const { data } = await publicFetch.get('collections');
        setCollections(data);
      } catch (err) {
        console.log(err);
      }
    };
    const getSlidesData = async () => {
      try {
        const { data } = await publicFetch.get('slide');
        setSlides(data);
      } catch (err) {
        console.log(err);
      }
    };
    getCollectionsData();
    getSlidesData();
  }, []);

  const change = () => {
    localStorage.setItem('cookiee', true);
  };

  const submit = async (email) => {
    try {
      setEmailLoading(true);
      const { data } = await publicFetch.post('sub', email);

      setEmailSuccess(data.message);
      setTimeout(() => {
        setEmailSuccess('');
        setEmailLoading(false);
      }, 1700);
    } catch (error) {
      setEmailLoading(false);
      const { data } = error.response;
      setEmailError(data.message);
      setEmailSuccess(null);
    }
  };

  const ShopItemContainer = ({ children }) => (
    <div
      className="rounded flex flex-col px-10 lg:flex-row "
      syle={{ backgourndColor: '#fff3f3' }}
    >
      {children}
    </div>
  );

  const formatItems = () => {
    let finalArr = [],
      columnItems = [];

    collections &&
      collections.forEach((collection, i) => {
        columnItems.push(
          <div
            key={collection._id}
            className="bg-white shadow-md rounded p-6 mb-5 mr-2 w-full  lg:w-1/4 "
          >
            <CollectionItem item={collection} />
          </div>
        );
        if ((i + 1) % 4 === 0) {
          finalArr.push(<ShopItemContainer>{columnItems}</ShopItemContainer>);
          columnItems = [];
        } else if (i === collections.length - 1) {
          finalArr.push(<ShopItemContainer>{columnItems}</ShopItemContainer>);
          columnItems = [];
        }
      });
    return finalArr;
  };

  if (collections) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Larcossa - Home</title>
          <link rel="canonical" href="https://larcossa.com" />
          <meta name="description" content="At Larcossa Cosmetics ..." />
        </Helmet>

        {accepted ? null : (
          <CookieConsent
            location="bottom"
            buttonText="Got It!"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#311803' }}
            buttonStyle={{
              backgroundColor: '#fff3f3',
              color: '#311803',
              fontSize: '13px',
            }}
            expires={150}
            onAccept={change}
          >
            This website uses cookies to enhance the user experience.{' '}
            <span className="text-pinkOther hover:text-pinkLight">
              <Link to="/privacy-policy">Learn More</Link>
            </span>
          </CookieConsent>
        )}

        <div className="bg-dark1 w-auto h-72 sm:h-80 md:h-112 lg:h-144 xl:h-full pt-16  lg:pt-16 overflow-hidden">
          <Flickity
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {slides.map((slide) => (
              <div key={slide._id} style={{ width: '100%', height: '615px' }}>
                <div className="absolute w-4/6 mx-auto px-10 sm:px-16 lg:px-20 xl:px-32 pt-16 sm:pt-24 xl:pt-32 mt-8 sm:mt-20 md:mt-20 lg:pt-32">
                  <h2 className="font-greatVibes text-white text-xl sm:text-2xl md:text-3xl pb-8 lg:text-4xl xl:text-6xl leading-5 xl:leading-none md:text-2xl">
                    {slide.caption}
                  </h2>
                </div>
                <img src={slide.image} alt="Larcossa Cosmetics" />
              </div>
            ))}
          </Flickity>
        </div>

        <div className="bg-pinkWhite container font-roboto mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ">
          <div className="flex flex-wrap justify-center">
            <div className="w-full">
              <h1 className="text-center text-red-800 font-roboto text-3xl  lg:text-6xl font-semibold">
                Be a Bold Force of Beauty
              </h1>
            </div>

            <Link to="/shop" className="text-red-800 font-bold ">
              <div
                className="rounded-full h-12 sm:h-16 w-12 md:w-16 lg:h-2o lg:w-2o xl:h-32 xl:w-32 flex items-center 
                     border-4 border-red-800 hover:bg-red-100 hover:shadow-inner shadow-md hover:text-red-900 hover:border-red-900 justify-center text-xs md:text-lg lg:text-xl xl:2xl"
              >
                SHOP
              </div>
            </Link>
          </div>
          <div className="py-4">
            <h2 className="font-greatVibes tracking-wider font-light text-center text-red-800 text-2xl lg:text-5xl">
              Must Haves
            </h2>
          </div>
          {formatItems()}
          {/* <div className='flex flex-wrap lg:flex-no-wrap justify-between'>
                     {
                          collections.map(collection=>
                          (<div key={collection._id} className='bg-white shadow-md rounded p-6 m-2 mx-5 w-full  lg:w-1/4 '><CollectionItem  item={collection} /></div>))
                     }
                </div> */}
        </div>

        <div
          className="container mx-auto lg:py-48 bg-cover bg-center"
          style={{ backgroundImage: 'url("/image2.jpeg")' }}
        >
          <div className="px-8 py-4 sm:py-16 lg:py-20 lg:px-20">
            <span>
              <h2 className="w-3/5 font-greatVibes text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl  leading-6 xl:leading-none py-8 md:py12">
                <span className="text-pinkOther text-2xl md:text-4xl lg:text-6xl">
                  LarcLuscious
                </span>
                <br />
                Velvety Smooth vegan lip colours giving your lips definition and
                leaving you colour enthusiasts popping all day
              </h2>
            </span>
          </div>
        </div>

        <div
          className="container mx-auto bg-cover bg-center"
          style={{ backgroundImage: 'url("/purple.jpg")' }}
        >
          <div className="flex lg:flex-row flex-col py-4 lg:py-20 px-8 lg:px-20">
            <div className="w-full lg:w-2/5 py-2">
              <h3 className="lg:pt-4 text-center text-xl sm:text-3xl lg:text-6xl font-playFair leading-none font-semibold px-12">
                JOIN THE <br className="hidden lg:block" />
                LARCFAM
              </h3>
            </div>
            <div className="w-full lg:w-3/5 pb-2">
              <h2 className=" font-playFair text-black text-sm sm:text-base pb-3 md:text-lg lg:text-xl xl:text-xl leading-4 md:leading-5 lg:leading-6 xl:leading-7">
                Subscribe to our mailing list and be the first to know about our
                exclusives & discounts
              </h2>
              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={(values) => {
                  console.log(values);
                  submit(values);
                }}
                validationSchema={EmailSchema}
              >
                {() => (
                  <Form className="">
                    <div>
                      <div className="flex">
                        <div className="w-full lg:w-2/3">
                          <FormInput
                            ariaLabel="Email"
                            name="email"
                            type="text"
                            placeholder="Email"
                          />
                        </div>
                        <div className="w-full lg:w-1/3 px-5 ">
                          <GradientButton
                            type="submit"
                            text="Join Us"
                            loading={emailLoading}
                          />
                        </div>
                      </div>
                      <div className="px-2">
                        {emailSuccess && (
                          <p className="text-dark1">{emailSuccess} </p>
                        )}
                        {emailError && (
                          <p className="text-red-900">{emailError} </p>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Home;
