import React, { useState, createContext } from 'react';
import {useHistory} from 'react-router-dom'
const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const expiresAt = localStorage.getItem('expiresAt');
  const userInfo = localStorage.getItem('userInfo');
  
  const setAuthInfo = ({token, expiresAt, userInfo}) =>{
    localStorage.setItem('token',token);
    localStorage.setItem('expiresAt',expiresAt);
    localStorage.setItem('userInfo',JSON.stringify(userInfo));
    setAuthState({token, expiresAt, userInfo})
  }

  const [authState, setAuthState] = useState({
    token,
    expiresAt,
    userInfo: userInfo ? JSON.parse(userInfo) : {}
  });

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');  
    localStorage.removeItem('userInfo'); 
    setAuthState({token:null, expiresAt:null, userInfo:{} });
    history.push('/login'); 
  }

  const isAuthenticated = () =>{
    if(!authState.token || !authState.expiresAt){
        return false;
    } 
    return (
      new Date().getTime() / 1000 < authState.expiresAt
    );
  }

  const isAdmin = () => {
    return authState.userInfo.role === 'admin';
  }
  const isUser = () => {
    return (authState.userInfo.role === 'user');
  }


  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        isAuthenticated,
        logout,
        isAdmin,
        isUser,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
