import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';

const ForgotPassSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is not complete')
});

const ForgotPassForm =({ onSubmit }) => {

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async (values, { resetForm }) =>{
          onSubmit(values, resetForm)
        }
      }
      validationSchema={ForgotPassSchema}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <div className="w-full flex flex-wrap justify-between px-4 py-4 md:px-8 lg:px-10 lg:py-10">
            <h2 className="w-full px-2 py-2 text-red-800">
            We will send a recovery link to registered email.
            Please enter your Email address below.</h2>
            
            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Email" />
              </div>
              <FormInput
                ariaLabel="Your Email"
                name="email"
                type="text"
                placeholder="Your Email"
              />
            </div>

          </div>
          <div className="flex">
            <div className="w-full sm:w-1/2 mt-6 text-xl">
              <GradientButton type="submit" text="Submit" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassForm;
