import React, {
useContext,
useState,
useEffect
} from 'react';
import PageTitle from '../components/common/PageTitle';
import Card from '../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from '../context/FetchContext';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import 'react-quill/dist/quill.snow.css';
import Label from './../components/common/Label';
import FormInput from './../components/FormInput';
import { useLocation, Redirect } from 'react-router-dom'
import ShopItemForm2 from '../components/ShopItemForm2';
  
const ManageShopItem = () => {
    const location = useLocation();
    const {state} = location;
    const fetchContext = useContext(FetchContext);
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
		
		const [name, setName] = useState('')
		const [color, setColor] = useState('')
		const [desc, setDesc] = useState('')
		const [unitPrice, setUnitPrice] = useState('')
		const [discountAmount, setDiscountAmount] = useState('')
		const [discountCode, setDiscountCode] = useState('')
		const [codeAmount, setCodeAmount] = useState('')
		const [soldOut, setSoldOut] = useState(state.soldOut)

		useEffect(() => {
			const getShop = async () => {
				try {
					const { data } = await fetchContext.authAxios.get(
						`shop/item/${state._id}`
					);
					console.log(data)
					setName(data.name)
					setColor(data.color)
					setDesc(data.desc)
					setUnitPrice(data.unitPrice)
					setDiscountAmount(data.discountAmount)
					setDiscountCode(data.discountCode)
					setCodeAmount(data.codeAmount)
					setSoldOut(data.soldOut)
				} catch (err) {
					console.log('the err', err);
				}
			};
			getShop();
		}, []);
    
	const onSubmit = async (e) => {
		e.preventDefault();
		const values = {name, color, desc, unitPrice, discountAmount, discountCode, codeAmount, soldOut, _id:state._id}
    try {

			const {data} = await fetchContext.authAxios.patch(
        `shop/${state._id}`, values
      );
			
			setSuccessMessage(data.message);
			setTimeout(()=>{setSuccessMessage(null)}, 3000)
			
    } catch (err) {
      const { data } = err.response;
			setErrorMessage(data.message);
			setTimeout(()=>{setErrorMessage(null)}, 3000)
    }
  };

  return (
    <>
    <PageTitle title={`Manage ${state.name} `}   />
      <Card className='w-full'>
        {successMessage && (
          <FormSuccess text={successMessage} />
        )}
				{errorMessage && <FormError text={errorMessage} />}
				
        <div className='flex flex-col lg:flex-row'>
				<form className=' w-full lg:w-2/3' onSubmit={onSubmit}>
					<div className="flex flex-wrap justify-between">
						<div className="w-full px-2 py-1">
									<div className="mb-1">
										<Label text="Name" />
									</div>
							<input
									className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
										name="name"
										type="text"
										value={name}
										onChange={e=>{setName(e.target.value)}}
									/>
						</div>

						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Color" />
							</div>
							<input
								className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
								name="color"
								type="text"
								value={color}
								onChange={e=>{setColor(e.target.value)}}
							/>
						</div>
						
						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Description" />
							</div>
							<input
								className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
								name="desc"
								type="text"
								value={desc}
								onChange={e=>{setDesc(e.target.value)}}
							/>
						</div>
						
						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Unit Price" />
							</div>
							<input
								className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
								name="unitPrice"
								type="number"
								value={unitPrice}
								onChange={e=>{setUnitPrice(e.target.value)}}
							/>
						</div>
						
						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Sales Discount" />
							</div>
							<input
								className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
								name="discountAmount"
								type="number"
								value={discountAmount}
								onChange={e=>{setDiscountAmount(e.target.value)}}
							/>
						</div>
						
						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Coupon Code" />
							</div>
							<input
								className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
								name="discountCode"
								type="text"
								value={discountCode}
								onChange={e=>{setDiscountCode(e.target.value)}}
							/>
						</div>
						
						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Coupon Amount" />
							</div>
							<input
								className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
								name="codeAmount"
								type="number"
								value={codeAmount}
								onChange={e=>{setCodeAmount(e.target.value)}}
							/>
						</div>
						
						<div className="w-full px-2 py-1">
							<div className="mb-1">
								<Label text="Sold Out" />
							</div>
								<input className="mr-2 leading-tight" type="checkbox" defaultChecked={soldOut} 
									onClick={()=>setSoldOut(!soldOut)} name="soldOut"/>
						</div>

					</div>  
					<div className="flex">
						<div className="w-full sm:w-1/4 mt-4">
							<GradientButton type="submit" text="Submit" />
						</div>
					</div>
				</form>
        </div>
      </Card>
  
    </>
  );
  };
  
  export default ManageShopItem;
  