import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email().required('Email is not complete'),
  mobile: Yup.number().required('Mobile Number is required'),
  enquiry: Yup.string().required('Enquiry is required'),
});

const ContactForm = ({ onSubmit }) => {

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        mobile: '',
        enquiry: ''
      }}
      onSubmit={async (values, { resetForm }) =>{
          onSubmit(values, resetForm)
        }
      }
      validationSchema={ContactSchema}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <div className="flex flex-wrap justify-between bg-white rounded-md shadow px-4 py-4 md:px-8 lg:px-10 lg:py-10">
            <h2 className="w-full px-2 py-2">Please fill the Enquiry form below and submit it.</h2>
            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Name" />
              </div>
              <FormInput
                ariaLabel="Your Name"
                name="name"
                type="text"
                placeholder="Your Name"
              />
            </div>

            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Email" />
              </div>
              <FormInput
                ariaLabel="Your Email"
                name="email"
                type="text"
                placeholder="Your Email"
              />
            </div>

            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Mobile Number" />
              </div>
              <FormInput
                ariaLabel="Your Mobile Number"
                name="mobile"
                type="text"
                placeholder="Your Mobile Number"
              />
            </div>
            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Enquire" />
              </div>
              <Field
                className="border border-gray-300 rounded p-1 w-full h-20 mb-2"
                component="textarea"
                name="enquiry"
                type="text"
                placeholder="Enquire"
              />
            </div>

          </div>
          <div className="flex">
            <div className="w-full sm:w-1/2 mt-6 font-charm text-xl">
              <GradientButton type="submit" text="send" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
