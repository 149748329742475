import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Link, useLocation, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FetchContext } from '../context/FetchContext';
import { AuthContext } from '../context/AuthContext';
import { CartContext } from './../providers/cart/cart.provider';

const ThankYou = () => {
     const authContext = useContext(AuthContext);
     const fetchContext = useContext(FetchContext);
     const [orderInfo, setOrderInfo] = useState([])
     const user = authContext.authState.userInfo;

     const values = {user};

     useEffect(()=>{
          const getOrderInfo = async() => {

               try {
                    const { data } = await fetchContext.authAxios.post(
                      'orderinfo', values
                    );
                    setOrderInfo(data.order);               
                   
               } catch (err) {
               console.log(err);
               const { data } = err.response;
               }
              
          
          }
          getOrderInfo(); 
         
      },[]);
      
     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Thank You</title>
                <link rel="canonical" href="https://larcossa.com/thankyou" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-32 ' >

                <div className='bg-white rounded-md shadow mt-20 py-10 lg:px-16 flex flex-col lg:flex-row items-center'>
                    <div className='w-full lg:w-1/2 px-10'>
                         {
                         
                              <div className='flex flex-col'>
                                   <h2 className='text-4xl text-red-800 font-semibold'>Order Summary:</h2>
                                   <span className='text-dark1 px-2 py-2 font-medium'>Order Reference: <span className='text-red-800'>{orderInfo.reference}</span></span>
                                   <span className='text-dark1 px-2 py-2 font-medium'>Order Amount: <span className='text-red-800'>Ksh. {orderInfo.total}</span></span>
                                   <span className='text-dark1 px-2 py-2 font-medium'>Delivery Place: <span className='text-red-800'>{orderInfo.deliveryPlace}</span></span>
                                   <span className='text-dark1 px-2 py-2 font-medium'>Delivery Fee:<span className='text-red-800'> Ksh.{orderInfo.deliveryFee}</span></span>
                                   {orderInfo.destinationDetails?<span className='text-dark1 px-2 py-2 font-medium'>Destination Details: 
                                   <span className='text-red-800'>{orderInfo.destinationDetails}</span></span>:null}
                              </div>
                              
                             
                         
                         }

                    </div>
                    <div className='w-full lg:w-1/2'>
                         <h2 className='text-pinkDark text-4xl lg:text-6xl px-10 py-10 center'>Thank You</h2> 
                    </div>

                </div>

          </div>
      
        </>
        );
  }


export default ThankYou;
