import React, {
    useContext,
    useState,
    useEffect
  } from 'react';
import PageTitle from '../components/common/PageTitle';
import Card from '../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from '../context/FetchContext';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ManageFaq = () => {
  const fetchContext = useContext(FetchContext);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [faq, setFaq] = useState('');
  const [value, setValue] = useState('');


  useEffect(() => {
    const getFaq = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          'faq'
        );
        const{faq} = data[0]
        setValue(faq);
        setFaq(data[0])
        
      } catch (err) {
        console.log(err);
      }
    };
    getFaq();
  }, [fetchContext]);
  const saveFaq = async faq => {
    try {
      const { data } = await fetchContext.authAxios.patch(
        'faq',
        faq
      );
      setErrorMessage(null);
      setSuccessMessage(data.message);
    } catch (err) {
      const { data } = err.response;
      setSuccessMessage(null);
      setErrorMessage(data.message);
    }
  };
  return (
    <>
      <PageTitle title="FAQs" />
      <Card>
        {successMessage && (
          <FormSuccess text={successMessage} />
        )}
        {errorMessage && <FormError text={errorMessage} />}
        <Formik
          initialValues={{
            
          }}
          onSubmit ={ async (values) => {
            values.faq = value
            values.id = faq._id
            saveFaq(values)
            }}
          enableReinitialize={true}
        >
          {() => (
            <Form>                
            <div className='py-2'>
              
              <div className="w-full lg:px-2 py-1">
             
                <ReactQuill theme="snow" value={value} onChange={setValue} className=' px-2 pb-4 lg:px-8 '/> 
              </div>
            </div>
            
            <div className='m-2'>
              <GradientButton text="Save" type="submit" />
            </div>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};
  
export default ManageFaq;
  