import React from 'react';

const PageTitle = ({ title }) => (
  <div className="my-1 sm:my-4">
    <h2 className="text-pink-900 font-bold text-2xl">
      {title}
    </h2>
  </div>
);

export default PageTitle;
