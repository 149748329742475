import React, {
    useContext,
    useEffect,
    useState
  } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PageTitle from '../components/common/PageTitle';
import { FetchContext } from '../context/FetchContext';
import { formatCurrency } from '../util';
import BlogForm from '../components/BlogForm';
import DangerButton from '../components/common/DangerButton';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import FormInput from './../components/FormInput';
import Card from './../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form} from 'formik';
import Label from './../components/common/Label';
import {Link} from 'react-router-dom'
import Hyperlink from './../components/common/Hyperlink'
  
const BlogContainer = ({ children }) => (
  <div className="bg-white rounded shadow-md mb-4 p-4 ">
    {children}
  </div>
);
   
const Blog = ({ item, onDelete }) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <img
        className="rounded w-full lg:w-32 h-full"
        src={item.image}
        alt="blog post"
      />
      <div className="flex justify-between flex-col l:flex-roww-full ">
        <div className="flex flex-col lg:ml-4 justify-between">
          <div>
            <p className="font-bold text-lg text-gray-900">
              {item.title}
            </p>
            <p className="text-sm text-pinkOther">
              {item.date}
            </p>

            <p className='pt-2 text-gray-600'>{item.desc} </p>
          </div>
        </div>
        <div className="self-end py-3">
          <Link to={{pathname: '/comments', state:item}} className='px-3 bg-pinkDark rounded shadow-md py-1 text-white hover:bg-pinkOther mr-3' >
          Comments</Link>
          <DangerButton
            text="Delete"
            onClick={() => onDelete(item)}
          />
        </div>
      </div>
    </div>
  );
};
  
const NewBlog = ({ onSubmit}) => {
  return (
    <section className="bg-white p-4 shadow-md rounded-md">
      <p className="font-bold mb-2">New Blog </p>
      <BlogForm onSubmit={onSubmit} />
    </section>
  );
};
  
const ManageBlog = () => {
  const fetchContext = useContext(FetchContext);
  const [blog, setBlog] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const getBlog = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          'blog'
        );
        setBlog(data);
      } catch (err) {
        console.log('the err', err);
      }
    };

    getBlog();
  }, [fetchContext]);

  const onSubmit = async (values, resetForm) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        'blog',
        values
      );
      setBlog([...blog, data.blog]);
      resetForm();
      setSuccessMessage(data.message);
      setErrorMessage(null);
    } catch (err) {
      const { data } = err.response;
      setSuccessMessage(null);
      setErrorMessage(data.message);
    }
  };

  const onDelete = async item => {
    try {
      if (
        window.confirm(
          'Are you sure you want to delete this Blog post?'
        )
      ) {
        const {
          data
        } = await fetchContext.authAxios.delete(
          `blog/${item._id}`
        );
        setBlog(
          blog.filter(
            item => item._id !== data.deletedItem._id
          )
        );
      }
    } catch (err) {
      const { data } = err.response;
      setErrorMessage(data.message);
    }
  };

  return (
    <>
      <PageTitle title="Blog Post" />

      {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}

      <div className="mb-4">
        <NewBlog onSubmit={onSubmit} />
      </div>
      {blog && blog.length
        ? blog.map(item => (
            <BlogContainer key={item._id}>
              <Blog
                item={item}
                onDelete={onDelete}
              />
            </BlogContainer>
          ))
        : 'No Blog posts'}
    </>
  );
};
  
  export default ManageBlog;
  