import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Link, useLocation } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './../components/common/Label';
import FormInput from './../components/FormInput';
import GradientButton from './../components/common/GradientButton';
import FormError from './../components/FormError';
import FormSuccess from './../components/FormSuccess';
import {publicFetch} from './../util/fetch';
import moment from 'moment';

const CommentSchema = Yup.object().shape({
     comment: Yup.string().required('Comment is required'),
     name: Yup.string().required(
       'Your Name is required'
     ),
   });

const BlogPost = () => {
     const location = useLocation();
     const [successMessage, setSuccessMessage] = useState();
     const [errorMessage, setErrorMessage] = useState();
     const {state:{title, image, article, date, _id}} = location;
     const [comments, setComments] = useState([]);


     useEffect(() => {
          const getComments = async () => {
            try {
              const { data } = await publicFetch.get(
                `comments/${_id}`
              );
              
              setComments(data)
            } catch (err) {
              console.log('the err', err);
            }
          };
      
          getComments();
          
        }, [publicFetch]);

     const onSubmit = async (values, resetForm) => {
          
          try {
            const { data } = await publicFetch.post(
              'comments',
              values
            );
            setComments(data.comments)
            resetForm();
            setSuccessMessage(data.message)
            setTimeout(()=>{setErrorMessage(null)}, 700);
            ;
          } catch (err) {
            const { data } = err.response;  
            setErrorMessage(data.message);          
            setTimeout(()=>{setSuccessMessage(null)}, 700);
            
          }
     };  

     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - {title}</title>
                <link rel="canonical" href="https://larcossa.com/blog" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-32 ' >

                <div className='bg-white py-10 lg:px-16'>
                   <div className='py-5 px-5 lg:px-10'>
                     <h2 className='text-red-800 text-3xl sm:text-4xl lg:text-5xl italic font-light py-3 font-playFair'>{title}
                     </h2>
                     <p className="text-pinkDark text-sm">{date.slice(0,15)} </p>
                   </div>
                    <div className='flex flex-wrap justify-center'>
                         <div className='px-5 lg:px-10'>
                              <img src={image} className='rounded shadow-md' />
                         </div>
                         <div className='text-black font-roboto px-5 md:px-10 lg:px-20'>
                              <div className='text-black font-roboto py-5 leading-7 lg:leading-8 font-normal'>
                                   <div dangerouslySetInnerHTML={{__html: article}} />
                              </div>        
                         </div>
                    </div>
                    <div className='text-pinkDark font-roboto px-5 lg:px-20'>
                         <p>By Larcossa</p>
                    </div>
                    <div className='px-5 lg:px-10 py-4 lg:py-6'>
                         <div className='text-black font-roboto px-5 md:px-10 lg:px-20'>
                              <h2 className='text-2xl pb-4'>Comments</h2>
                              <div className='border-gray-200 border rounded px-4'>
                              {comments.map(comment=>(
                                   <div className='flex flex-col py-3'>
                                        <div className='flex flex-row items-center'>
                                             <span className='text-lg text-gray-800'>{comment.name}</span>  
                                             <span className='text-xs px-3 text-gray-700'>{moment(comment.createdAt).format('hh:mm Do MM YY')}</span>
                                        </div>
                                        <div className='text-sm font-roboto text-dark1'>
                                             {comment.comment}
                                        </div>
                                   </div>
                              ))}
                              </div>
                              <Formik
                                   initialValues={{
                                   comment: '',
                                   name: '',
                                   }}
                                   onSubmit={ (values, { resetForm }) =>{
                                        values.blogId=_id
                                        
                                        onSubmit(values, resetForm)
                                   }
                                   }
                                   validationSchema={CommentSchema}
                                   validateOnBlur={false}
                              >
                                   {() => (
                                   <Form>
                                        <div className="flex flex-wrap justify-between ">
                                             <div className="w-full px-2 py-3">
                                                  <div className="mb-1">
                                                       <Label text="Add Comment" />
                                                  </div>
                                                  <Field
                                                       className="border border-gray-300 rounded p-1 w-full h-20 mb-2"
                                                       component="textarea"
                                                       name="comment"
                                                       type="text"
                                                       placeholder="Comment here"
                                                  />
                                             </div>
                                             <div className="w-full lg:w-1/2 px-2 py-1">
                                                  <div className="mb-1">
                                                       <Label text="Your Name" />
                                                  </div>
                                                  <FormInput
                                                       ariaLabel="Your Name"
                                                       name="name"
                                                       type="text"
                                                       placeholder="Your Name"
                                                  />
                                             </div>
                                             
                                        </div>
                                        <div className="flex pb-4">
                                             <div className="w-full sm:w-1/4 mt-4">
                                                  <GradientButton type="submit" text="Comment" />
                                             </div>
                                        </div>
                                   </Form>
                                   )}
                              </Formik>
                              {successMessage && (
                              <FormSuccess text={successMessage} />
                              )}
                              {errorMessage && <FormError text={errorMessage} />}
                                    
                         </div>
                         
                    </div>
                    

                </div>
               

          </div>
      
        </>
        );
  }


export default BlogPost;
