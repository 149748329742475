import React from 'react';
import Header from './components/Header' 
import TheFooter from './components/TheFooter';

const FrontShell = ({ children }) => {
  return (
    <>
      <Header />
       {children}
      <TheFooter />
    </>
  );
};

export default FrontShell;
