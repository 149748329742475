import React, { createContext, useState, useEffect } from 'react';

import {
  addItemToCart,
  removeItemFromCart,
  filterItemFromCart,
  getCartItemsCount,
  getCartTotal,
  getSubTotal,
} from './cart.utils';

export const CartContext = createContext({
  hidden: true,
  toggleHidden: () => {},
  cartItems: [],
  addItem: () => {},
  removeItem: () => {},
  clearItemFromCart: () => {},
  cartItemsCount: 0,
  cartTotal: 0,
  subtotal: 0,
  deliveryPlace: '',
  deliveryFee:0,
  discount:0,
  updateDiscount: () => {},
  addDestinationDetails: () => {},
  destinationDetails: ''
});

const CartProvider = ({ children }) => {
  const cartItemsState = localStorage.getItem('cartItems')?JSON.parse(localStorage.getItem('cartItems')):[];
  const deliveryFeeState = localStorage.getItem('deliveryFee')?localStorage.getItem('deliveryFee'): 0 ;
  const deliveryPlaceState = localStorage.getItem('deliveryPlace')?localStorage.getItem('deliveryPlace'): '' ;
  const discountState = localStorage.getItem('discount')?localStorage.getItem('discount'): 0 ;
  const destinationDetailsState = localStorage.getItem('destinationDetails')?localStorage.getItem('destinationDetails'): '' ;

  const [hidden, setHidden] = useState(true);
  const [cartItems, setCartItems] = useState(cartItemsState);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(deliveryFeeState);
  const [deliveryPlace, setDeliveryPlace] = useState(deliveryPlaceState);
  const [discount, setDiscount] = useState(discountState);
  const [destinationDetails, setDestinationDetails] = useState(destinationDetailsState);

  const addItem = item => setCartItems(addItemToCart(cartItems, item));
  const removeItem = item => setCartItems(removeItemFromCart(cartItems, item));
  const toggleHidden = () => setHidden(!hidden);
  const clearItemFromCart = item => setCartItems(filterItemFromCart(cartItems, item));
  const updateDeliveryPlace = (place) => setDeliveryPlace(place);
  const updateDeliveryFee = (fee) => setDeliveryFee(fee);
  const updateDiscount = amount => setDiscount(amount);
  const addDestinationDetails = (details) => (setDestinationDetails(details))

  useEffect(() => {
    setCartItemsCount(getCartItemsCount(cartItems));   
    setCartTotal(getCartTotal(cartItems, deliveryFee, discount));
    setSubTotal(getSubTotal(cartItems));
    localStorage.setItem('cartItems',JSON.stringify(cartItems));
    localStorage.setItem('deliveryPlace', deliveryPlace);
    localStorage.setItem('subTotal', subTotal);
    localStorage.setItem('deliveryFee', deliveryFee);
    localStorage.setItem('discount', discount);
    localStorage.setItem('destinationDetails', destinationDetails);

  }, [cartItems,cartTotal,cartItemsCount, deliveryFee, discount, destinationDetails]);

  return (
    <CartContext.Provider
      value={{
        hidden,
        toggleHidden,
        cartItems,
        addItem,
        removeItem,
        clearItemFromCart,
        cartItemsCount,
        cartTotal,
        subTotal,
        deliveryPlace,
        deliveryFee,
        updateDeliveryPlace,
        updateDeliveryFee,
        discount,
        updateDiscount,
        destinationDetails,
        addDestinationDetails
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
