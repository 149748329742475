import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import Flickity from 'react-flickity-component'
import { Link } from 'react-router-dom';
import { FiFacebook, FiTruck, FiInstagram, FiShield} from 'react-icons/fi';
import { BiSupport } from "react-icons/bi";
import { AiOutlineTag } from "react-icons/ai";
import { AuthContext } from './../context/AuthContext';
import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import {publicFetch} from './../util/fetch'
import ColletionItem from './../components/collection-item/collection-item.component'
import CollectionItem from './../components/collection-item/collection-item.component';

const Shipping = () => {
     const [shipping, setShipping] = useState([]);
     
     useEffect(()=>{
        const getShippingData = async() => {
            try {
              const { data } = await publicFetch.get(
                'shipping'
              );
              const {shipping} = data[0];
              setShipping(shipping);
            } catch (err) {
              console.log(err);
            }
        };
        getShippingData();
    },[])
    console.log(shipping)

     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Shipping Policy</title>
                <link rel="canonical" href="https://larcossa.com/shipping-policy" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10'>
                   <div className='py-5 px-5 lg:px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light'>Shipping Policy</h2>
                   </div>
                    <div className='flex flex-wrap justify-center'>                   
                         <div className='w-full px-5 lg:px-10 text-red-900'>
                              
                              <div dangerouslySetInnerHTML={{__html: shipping}} />
                         </div>
                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default Shipping;
