import React,{useContext} from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faAddressCard,
  faStore,
  faDollyFlatbed,
  faUserFriends,
  faHome,
  faPenSquare,
  faBlog,
  faLocationArrow,
  faDatabase,
  faFileInvoice,
  faAddressBook,
  faArrowLeft,
  faUserSecret,
  faMinusSquare,
  faTruck,
  faQuestion,
  faDotCircle,
  faAt,
  faGift
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import logo from './../images/logo.png';
import { AuthContext } from '../context/AuthContext';

const navItems = [
  {
    label: 'Orders',
    path: 'dashboard',
    icon: faChartLine,
    allowedRoles: ['admin']
  },
  {
    label: 'Shop',
    path: 'manage-shop',
    icon: faStore,
    allowedRoles: ['admin']
  },
  {
    label: 'Users',
    path: 'users',
    icon: faUserFriends,
    allowedRoles: ['admin']
  },
  {
    label: 'Home',
    path: 'manage-home',
    icon: faHome,
    allowedRoles: ['admin']
  },
  {
    label: 'About',
    path: 'manage-about',
    icon: faAddressBook,
    allowedRoles: ['admin']
  },
  {
    label: 'Makeup',
    path: 'manage-makeup',
    icon: faPenSquare,
    allowedRoles: ['admin']
  },
  {
    label: 'Blog',
    path: 'manage-blog',
    icon: faBlog,
    allowedRoles: ['admin']
  },
  {
    label: 'Email Subs',
    path: 'manage-sub',
    icon: faAt,
    allowedRoles: ['admin']
  },
  {
    label: 'Privacy',
    path: 'manage-privacy',
    icon: faUserSecret,
    allowedRoles: ['admin']
  },
  {
    label: 'Returns',
    path: 'manage-returns',
    icon: faArrowLeft,
    allowedRoles: ['admin']
  },
  {
    label: 'Shipping ',
    path: 'manage-shipping',
    icon: faTruck,
    allowedRoles: ['admin']
  },
  {
    label: 'Terms',
    path: 'manage-terms',
    icon: faMinusSquare,
    allowedRoles: ['admin']
  },
  {
    label: 'FAQs',
    path: 'manage-faqs',
    icon: faQuestion,
    allowedRoles: ['admin']
  },
  {
    label: 'Contact',
    path: 'manage-contact',
    icon: faDotCircle,
    allowedRoles: ['admin']
  },
  {
    label: 'Dashboard',
    path: 'user-dshboard',
    icon: faDatabase,
    allowedRoles: ['user']
  },
  {
    label: 'Addresses',
    path: 'manage-blog',
    icon: faLocationArrow,
    allowedRoles: ['user']
  },
  {
    label: 'My Orders',
    path: 'manage-blog',
    icon: faFileInvoice,
    allowedRoles: ['user']
  },
];

const NavItem = ({ navItem }) => {
  const location = useLocation();
  const isCurrentRoute =
    location.pathname === `/${navItem.path}`;
  const classes = classNames({
    'px-3 sm:px-6 justify-center sm:justify-start py-2 rounded-full flex': true,
    'text-gray-600 hover:text-pink-500 transform hover:translate-x-1 transition ease-in-out duration-100': !isCurrentRoute,
    'bg-gradient text-gray-900 shadow-lg': isCurrentRoute
  });
  return (
    <Link to={navItem.path} className={classes}>
      <div className="flex items-center">
        <div className="mr-0 sm:mr-2 ">
          <FontAwesomeIcon icon={navItem.icon} />
        </div>
        <span className="hidden sm:block">
          {navItem.label}
        </span>
      </div>
    </Link>
  );
};

const NavItemContainer = ({ children }) => (
  <div>{children}</div>
);

const Sidebar = () => {
  const authContext = useContext(AuthContext);
  const {role} = authContext.authState.userInfo; 
  return (
    <section className="h-screen">
      <div className="w-10 sm:w-40 m-auto hidden md:block">
        <img src={logo} rel="logo" alt="Logo" />
      </div>
      <div className="mt-20">
        {navItems.map((navItem, i) => (
          <>
            {navItem.allowedRoles.includes(role)&&(
              <NavItemContainer key={i}>
                <NavItem navItem={navItem} />
              </NavItemContainer>
            )}
          </>
          
        ))}
      </div>
    </section>
  );
};

export default Sidebar;
