import React, {useState, useEffect, useContext} from 'react';
import {Helmet} from "react-helmet";
import CheckoutItem from './../components/checkout-item/checkout-item.component';
import { AuthContext } from '../context/AuthContext';
import {Link} from 'react-router-dom';
import { FetchContext } from '../context/FetchContext';
import GradientButton from './../components/common/GradientButton';
import { CartContext } from './../providers/cart/cart.provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Input from './../components/common/Input'
import Label from './../components/common/Label';
import ReactPixel from 'react-facebook-pixel';

const MyAccount = () => {
     
     const authContext = useContext(AuthContext);
     const cartContext = useContext(CartContext);
     const fetchContext = useContext(FetchContext);
     const [pickups, setPickups ] = useState([]);
     const [doorsteps, setDoorsteps ] = useState([]);
     const [listData, setListData ] = useState([]);
     const [method, setMethod] = useState([]);
     const [destinationDetails, setDestinationDetails] = useState('');
     const [redirect, setRedirect] = useState(false);
     const [pesapalUrl, setPesapalUrl] = useState('');
     const [payLoading, setPayLoading] = useState(false);
     const [discountCode, setDiscountCode] = useState('');
     const [discountAmount, setDiscountAmount] = useState(0);
     const [code, setCode] = useState(false);
     const [agreed, setAgreed] = useState(true);
     const [detailsInput, setDetailsInput] = useState(false)
     
     const [orderCount, setOrderCount] = useState(0);
     const [orders, setOrders] = useState([]);
     useEffect(()=>{
          const getPickupData = async() => {
              try {
                const { data } = await fetchContext.authAxios.get(
                  'pickup'
                );
                setPickups(data);
              } catch (err) {
                console.log(err);
              }
          };
          const getDoorstepData = async() => {
                 try {
                 const { data } = await fetchContext.authAxios.get(
                 'doorstep'
                 );
                 setDoorsteps(data);
                 } catch (err) {
                 console.log(err);
                 }
            };
            
          const getOrderData = async() => {
               const value = {id:authContext.authState.userInfo._id};
               try {
               const { data } = await fetchContext.authAxios.post(
               'myorders', value 
               );
               setOrderCount(data.length);
               setOrders(data)
               } catch (err) {
               console.log(err);
               }
          };
          getOrderData();
          getPickupData();
          getDoorstepData();
          cartContext.addDestinationDetails(destinationDetails);
      },[destinationDetails]);

      const handleData = (event) =>{
          setDetailsInput(true)
           if(event.target.value==='pickup'){
               setListData(pickups)
               setMethod(' for Pickup Stores')
           }
           if(event.target.value==='doorstep'){
               setListData(doorsteps)
               setMethod(' for Doorstep Delivery')
               
           }
           if(event.target.value==='outside'){
               cartContext.updateDeliveryPlace('Outside Nairobi')
               cartContext.updateDeliveryFee('300')
               
           }
      }

      const handleChange=(e)=>{
          if(method===' for Pickup Stores'){
               const dplace = pickups.find(pickup=>pickup._id===e.target.value)
               cartContext.updateDeliveryPlace(dplace.zone+' - '+dplace.place)
               cartContext.updateDeliveryFee(dplace.amount)
          }else{
               const dplace = doorsteps.find(doorstep=>doorstep._id===e.target.value)
               cartContext.updateDeliveryPlace(dplace.place)
               cartContext.updateDeliveryFee(dplace.amount)
          }

      }

      const pay = async () => {
           

          try {
               setPayLoading(true);
               const email = authContext.authState.userInfo.email;
               const cartTotal = cartContext.cartTotal;
               const user = authContext.authState.userInfo;
               const deliveryPlace = cartContext.deliveryPlace;
               const deliveryFee = cartContext.deliveryFee;
               const items = cartContext.cartItems;
               const discount = cartContext.discount;
               const destinationDetails = cartContext.destinationDetails;

               const payload = {email, cartTotal, user, deliveryPlace, deliveryFee, items, discount, destinationDetails}
               
               const {data} = await fetchContext.authAxios.post('pay', payload);

               setPesapalUrl(data.url)
               setRedirect(true)
          //   setEmailSuccess(data.message);
          //   setTimeout(()=>{setEmailSuccess('');setEmailLoading(false);}, 1700);
          } catch (error) {
            setPayLoading(false);
            const { data } = error.response;
          //   setEmailError(data.message);
          //   setEmailSuccess(null);
          }
        };

     const wrongCode = () =>{
          setCode(true)
     }

     // const handleAgree = () =>{
     //      setAgreed(!agreed); 
     //      localStorage.setItem('agreed', !agreed)
     // }

     const checkDiscount = async () =>{
          setCode(false)

          try {
               const { data } = await fetchContext.authAxios.get(`/shop/${discountCode}`);

               setDiscountAmount(data.discount)
               cartContext.updateDiscount(data.discount)

          } catch (err) {

               wrongCode();
          }
     }
     const ddChange = (e) => {
          setDestinationDetails(e.target.value);
          setDetailsInput(false);
     }

     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - My Account</title>
                <link rel="canonical" href="https://larcossa.com/my-account" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
           <div className='hidden'>{redirect && (window.location.href = pesapalUrl)}</div>
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 md:px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10'>
                   <div className='py-5 px-10 lg:px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light py-3'>My Account</h2>
                   </div>
                    <div className='flex flex-wrap lg:flex-no-wrap justify-center'>
                         <div className='w-full lg:w-1/4 px-5 '>
                             <div className='bg-white rounded shadow-md lg:mx-2 px-5 py-4 mb-8'>
                               <h2 className='text-red-800 text-lg lg:text-2xl font-semibold lg:py-3'>Menu</h2>
                               <h2 className='text-red-800 text-base lg:text-lg font-light lg:py-3'>
                               {orderCount>0
                               ?<Link to={{pathname:'/my-orders', state:orders }}>My Orders - {orderCount}</Link>
                               :<span className='hover:text-pinkOther'>My Orders - {orderCount}</span>}
                               <br/><br className='hidden lg:block'/>
                               <Link to='' className='font-semibold text-pinkDark' onClick={authContext.logout}>LOGOUT</Link></h2>
                             </div>
                             
                         </div> 
                         <div className='w-full lg:w-3/4 lg:px-10 text-red-900 bg-white lg:rounded lg:shadow-md lg:mx-2'>
                              <div className=' px-5 md:px-5 py-5'>

                                   <div className='flex justify-between border-b pb-3 border-red-500 w-full text-dark1 '>
                                        <div className='header-block'>
                                             <span>Product</span>
                                        </div>
                                        <div className='header-block'>
                                             <span>Description</span>
                                        </div>
                                        <div className='header-block'>
                                             <span>Quantity</span>
                                        </div>
                                        <div className='header-block'>
                                             <span>Price</span>
                                        </div>
                                        <div className='header-block'>
                                             <span>Remove</span>
                                        </div>
                                   </div>
                                   {cartContext.cartItems.map(cartItem => (
                                        <CheckoutItem key={cartItem._id} cartItem={cartItem} />

                                   ))}
                                   

                                   <div className=' flex flex-wrap lg:flex-no-wrap w-full justify-between '>
                                        <div className='w-full lg:w-1/3 lg:mr-2  py-4'>
                                             
                                             <div className=' w-full flex flex-col lg:flex-row items-end ' >
                                                  <div className="w-full lg:w-2/3 lg:pl-2 py-2 ">
                                                       <div className="">
                                                            <Label text="Discount Code" />
                                                       </div>
                                                       
                                                       <input
                                                       className='w-full bg-grey-100 border rounded shadow px-2'
                                                       type="text"
                                                       placeholder="Enter Discount Code"
                                                       onChange={(e)=>setDiscountCode(e.target.value)}
                                                  />
                                                  
                                                  </div>
                                                  <div className='w-full lg:w-1/3 '>
                                                       <button className='bg-dark1 text-pinkOther lg:float-right rounded shadow-md px-4 py-2' onClick={checkDiscount}>Apply</button>
                                                  </div>
                                             </div>

                                             {code?<span className='text-red-500 text-sm'>Wrong Code</span>:null}
                                        </div>
                                        <div className=' w-full lg:w-2/3 flex flex-col lg:pt-2 lg:px-5'>
                                             <span className=' w-full float-right lg:text-xl text-dark1'>
                                                       <p className=' float-right py-1'>SUBTOTAL: Ksh {cartContext.subTotal}</p>
                                             </span>
                                        {
                                             cartContext.deliveryFee>0
                                             ?<div className=' w-full text-red-800'>
                                                  <div className='flex items-end' >
                                                       <div className=' w-full lg:w-5/6 float-right'>
                                                            <p className='lg:float-right'>{cartContext.deliveryPlace}</p> </div>
                                                       <div className=' text-lg w-full lg:w-1/6'>
                                                            <p className='float-right'>Ksh {cartContext.deliveryFee}</p></div>
                                                  </div>
                                             </div>
                                             :null
                                        }
                                             <div className=' w-full text-lg leading-8'>
                                                  

                                                  {cartContext.discount>0?
                                                  <span className=' float-right w-full'>
                                                       <p className='float-right py-1'>Discount: Ksh {cartContext.discount}</p>
                                                  </span>:null}
                                                  <span className=' w-full float-right lg:text-2xl text-dark1'>
                                                       <p className=' float-right py-1'>TOTAL: Ksh {cartContext.cartTotal}</p>
                                                  </span>
                                             </div>
                                        </div>
                                   </div>

                                   <div className='py-8  w-full'>
                                        <h2 className='text-xl'>Shipping Method</h2>
                                        <p>Please select a <span className="font-semibold text-lg text-red-400">shipping method</span> then pick a <span className="font-semibold text-lg text-red-400">destination</span> (except OutSide Nairobi Area Shipping) and then finally enter the <span className="font-semibold text-lg text-red-400">destination details</span> below:</p>
                                        <div className=''>
                                             <div className='w-full '>
                                                  <div className='px-2 py-2' onChange={handleData}>
                                                       {/* <div className='px-5 py-2'><input className='px-2 py-2' type="radio" value="pickup" name="shipping" /> PickUp Stores <span className="text-gray-800">Shipping</span></div> */}
                                                       <div className='px-5 py-2'><input className='px-2 py-2' type="radio" value="doorstep" name="shipping" /> Doorstep Delivery <span className="text-gray-800">Shipping</span></div>
                                                       <div className='px-5 py-2'><input className='px-2 py-2' type="radio" value="outside" name="shipping" /> OutSide Nairobi Area <span className="text-gray-800">Shipping</span> <span className="text-green-700">( Flat Rate of Ksh. 300)</span></div>

                                                       
                                                  </div>
                                             </div>
                                             <div className='w-full '>

                                                  <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 text-base py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" onChange={handleChange}>
                                                  <option>Pick a destination {method}:</option>
                                                  {listData.map(list=>(
                                                       <option value={list._id} key={list._id}>
                                                       { list.zone }  - { list.place } -
                                                       Ksh {list.amount}
                                                       </option>
                                                  ))}
                                                  </select>
                                                  <div className="w-full md:w-3/4 py-4">
                                                            
                                                            <h2 className='text-xl lg:pt-4 '>Destination Details: </h2><textarea className="appearance-none block w-full bg-white text-gray-700 border border-gray-500 rounded py-10 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Please enter DETAILS about your DESTINATION. Type 'Village Market' if you selected that option." name='destinationDetails' 
                                                            onChange={(e)=>{ddChange(e)}}></textarea>
                                                            {detailsInput?<span className='text italic text-red-600 py-4'>Enter destination details to activate Payment Button</span>:null}
                                                       </div>
                                             

                                             </div>
                                             
                                        </div>
                                   </div>
                                   
                                   {/* <p className='italic font-playFair text-lg text-pinkOther px-3'>Psst! Enjoy a special delivery rate when you checkout with the <span className='font-bold'>Pickup</span> store option.</p> */}
                                   <p className='italic font-playFair text-lg text-pinkOther px-3'>All payments are secure and encrypted</p>
                         </div>
                         {/* <div className="md:flex md:items-center mb-6 px-8 ">
                              <div className="md:w-1/3"></div>
                              <label className="md:w-2/3 block text-gray-700 font-bold">
                                   <input className="mr-2 leading-tight" checked={agreed}
                                   type="checkbox" onClick={()=> handleAgree() }/>
                                   <span className="text-sm">
                                         I agree to the terms and conditions.
                                   </span>
                              </label>
                         </div> */}
                              <div className='float-right py-5 px-5'>
                                   
                                   {(cartContext.destinationDetails.trim()!==''&&cartContext.destinationDetails.length>5)
                                   &&cartContext.deliveryFee>0
                                   &&cartContext.cartItemsCount>0
                                   &&agreed
                                   ?<div>
                                   <GradientButton
                                   type="button"
                                   text='MAKE PAYMENT'
                                   loading={payLoading}
                                   onClick={pay}/>
                                   </div>
                                   :
                                   <span>
                                   <button className='text-white shadow-md rounded-full bg-gray-400 py-2 px-8 float-right'
                                   >MAKE PAYMENT
                                   </button>

                                   <br/>
                                   {agreed
                                   ?<div>
                                        {cartContext.cartItemsCount>0
                                        ?<div>
                                             {!destinationDetails
                                             ?<span className='py-4 text-xs text-gray-700 float-right'>Enter Destination Details to Activate button</span>
                                             :null}
                                        </div>
                                        :
                                        <span className='py-4 text-xs text-gray-700 float-right'>Put a Product in BAG to Activate button</span>
                                        }
                                   </div>
                                   :
                                   <span className='py-4 text-xs text-gray-700 float-right'>Accept terms and conditions to Activate button</span>}
                                   </span>
                                   }
                                   
                              </div>
                         </div>
                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default MyAccount;
