import React, {useContext,useEffect,useState} from 'react';
import PageTitle from '../components/common/PageTitle';
import { FetchContext } from '../context/FetchContext';
import Card from '../components/common/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

const EmailDetail = ({ email, onDelete }) => (
    <div>
      <div className="flex w-full">
        <div className='b w-full py-2 flex flex-row justify-between'>
          <p className="w-full">
            {email.email}
          </p>
          <div className='text-pinkOther px-2'>
              <FontAwesomeIcon icon={faTrashAlt} className='text-xl hover:text-red-600'  onClick={()=> onDelete(email)}/>
          </div>
        </div>
      </div>
    </div>
  );

const ManageSub =()=>{

    const fetchContext = useContext(FetchContext);    
    const [emailList, setEmailList] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();

    useEffect(()=>{
        const getEmails = async () => {
            try {
              const { data } = await fetchContext.authAxios.get(
                'sub'
              );
              setEmailList(data);
            } catch (err) {
              console.log(err);
            }
          };
        getEmails();
    }, [fetchContext])
    console.log(emailList)

    const onDelete = async email => {

      try {
        if (
          window.confirm(
         'Are you sure you want to delete this email ?'
          )
        ) {
          const {data} = await fetchContext.authAxios.delete(
         `sub/${email._id}`
        );
        setEmailList(
         emailList.filter(
           email => email._id !== data.deletedEmail._id
           )
         );
        setSuccessMessage(data.message);
        }
      } catch (err) {
        const { data } = err.response;
        setErrorMessage(data.message);
      }
       };

    return(
        <>
            <PageTitle title="Email Subscriptions" />
            {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}
            <Card>
              <div className="flex flex-col">
                  {emailList.length &&
                  emailList.map(email => (
                      <div className="mx-1 px-3">
                          <EmailDetail email={email} onDelete={onDelete}/>
                      </div>
                  ))}
              </div>
            </Card>
        </>
    )

}

export default ManageSub