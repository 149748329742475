import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import PageTitle from '../components/common/PageTitle';
import { FetchContext } from '../context/FetchContext';
import { formatCurrency } from '../util';
import ShopItemForm from '../components/ShopItemForm';
import Button from './../components/common/Button';
import DangerButton from '../components/common/DangerButton';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import FormInput from './../components/FormInput';
import Card from './../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form } from 'formik';
import Label from './../components/common/Label';
import { Redirect, Link } from 'react-router-dom';

const CategorySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const ShopItemContainer = ({ children }) => (
  <div className="bg-white rounded shadow-md mb-4 p-4 flex flex-col lg:flex-row ">
    {children}
  </div>
);

const ShopItem = ({ item, onDelete }) => {
  return (
    <div className=" flex flex-col">
      <Link to={{ pathname: `/manage-item`, state: item }}>
        <img
          className="rounded w-4/5 lg:w-full h-full"
          src={item.image}
          style={{ maxWidth: '223px', maxHeight: '246px' }}
          alt="shop item"
        />
      </Link>
      <p className="font-bold text-xl text-gray-900">
        <Link to={{ pathname: `/manage-item`, state: item }}>{item.name}</Link>
      </p>
      <div className="py-5">
        <DangerButton text="Delete" onClick={() => onDelete(item)} />
      </div>
    </div>
  );
};

const NewShopItem = ({ onSubmit, categories }) => {
  return (
    <section className="bg-white p-4 shadow-md rounded-md">
      <p className="font-bold mb-2">New Shop Item</p>
      <ShopItemForm onSubmit={onSubmit} categories={categories} />
    </section>
  );
};

const Shop = () => {
  const fetchContext = useContext(FetchContext);
  const [shop, setShop] = useState([]);
  const [category, setCategory] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isActive, setActive] = useState(false);

  const buttonClasses = `bg-green-400 my-6 inline-flex justify-center w-full lg:w-1/4 rounded-md px-10 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-500 active:text-gray-200 transition ease-in-out duration-150`;

  useEffect(() => {
    const getShop = async () => {
      try {
        const { data } = await fetchContext.authAxios.get('shop');
        setShop(data);
      } catch (err) {
        console.log('the err', err);
      }
    };
    const getCategory = async () => {
      try {
        const { data } = await fetchContext.authAxios.get('category');
        setCategory(data);
      } catch (err) {
        console.log('the err', err);
      }
    };

    getShop();
    getCategory();
  }, [fetchContext]);

  const onSubmit = async (values, resetForm) => {
    try {
      const { data } = await fetchContext.authAxios.post('shop', values);
      setShop([...shop, data.shopItem]);
      resetForm();
      setSuccessMessage(data.message);
      setErrorMessage(null);
    } catch (err) {
      const { data } = err.response;
      setSuccessMessage(null);
      setErrorMessage(data.message);
    }
  };

  const saveCategory = async (values) => {
    try {
      const { data } = await fetchContext.authAxios.post('category', values);
      setCategory([...category, data.category]);
      setErrorMessage(null);
      setSuccessMessage(data.message);
      setActive(!isActive);
    } catch (err) {
      const { data } = err.response;
      setSuccessMessage(null);
      setErrorMessage(data.message);
    }
  };

  const onCheck = async (item) => {
    try {
      const { data } = await fetchContext.authAxios.patch(
        `shop/${item._id}`,
        item
      );
      setSuccessMessage(data.message);
      setShop(shop.filter((item) => item._id !== data.shopItem._id));
      setShop([...shop, data.shopItem]);
    } catch (err) {
      const { data } = err.response;
      setErrorMessage(data.message);
    }
  };
  const onDelete = async (item) => {
    try {
      if (window.confirm('Are you sure you want to delete this item?')) {
        const { data } = await fetchContext.authAxios.delete(
          `shop/${item._id}`
        );
        setShop(shop.filter((item) => item._id !== data.deletedItem._id));
      }
    } catch (err) {
      const { data } = err.response;
      setErrorMessage(data.message);
    }
  };

  const formatItems = () => {
    let finalArr = [],
      columnItems = [];

    shop &&
      shop.forEach((item, i) => {
        columnItems.push(
          <div key={item._id} className="w-full md:w-1/2 lg:w-1/4  lg:px-2">
            <ShopItem item={item} onDelete={onDelete} />
          </div>
        );
        if ((i + 1) % 4 === 0) {
          finalArr.push(<ShopItemContainer>{columnItems}</ShopItemContainer>);
          columnItems = [];
        } else if (i === shop.length - 1) {
          finalArr.push(<ShopItemContainer>{columnItems}</ShopItemContainer>);
          columnItems = [];
        }
      });
    return finalArr;
  };

  return (
    <>
      <PageTitle title="Shop" />

      {successMessage && <FormSuccess text={successMessage} />}
      {errorMessage && <FormError text={errorMessage} />}

      <button onClick={() => setActive(!isActive)} className={buttonClasses}>
        ADD CATEGORY
      </button>

      <div
        className={classNames('w-full lg:w-1/2 mb-6 rounded-md shadow-md', {
          block: isActive,
          hidden: !isActive,
        })}
      >
        <Card>
          <Formik
            initialValues={{
              name: '',
              linkUrl: '',
            }}
            onSubmit={(values) => {
              const name = values.name.toLowerCase().replace(/\s+/g, '');
              values.linkUrl = 'shop/' + name;
              saveCategory(values);
            }}
            validationSchema={CategorySchema}
            enableReinitialize={true}
          >
            {() => (
              <Form>
                <div className="py-2">
                  <div className="w-full lg:w-1/2 px-2 py-1">
                    <div className="mb-1">
                      <Label text="Category Name" />
                    </div>
                    <FormInput
                      ariaLabel="Category Name"
                      name="name"
                      type="text"
                      placeholder="Category Name"
                    />
                  </div>
                </div>
                <GradientButton text="Save" type="submit" />
              </Form>
            )}
          </Formik>
        </Card>
      </div>

      <div className="mb-4">
        <NewShopItem onSubmit={onSubmit} categories={category} />
      </div>
      {shop && shop.length > 0 ? formatItems() : 'No Shop Items'}
    </>
  );
};

export default Shop;
