import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Link, useLocation, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FetchContext } from '../context/FetchContext';
import { AuthContext } from '../context/AuthContext';
import { CartContext } from './../providers/cart/cart.provider';

const Order = () => {
     const authContext = useContext(AuthContext);
     const cartContext = useContext(CartContext);
     const [status, setStatus] = useState('');
     const [order, setOrder] = useState('')
     const fetchContext = useContext(FetchContext);
     const location = useLocation();
     const query = new URLSearchParams(location.search)
     const transaction = query.get('pesapal_transaction_tracking_id');
     const reference = query.get('pesapal_merchant_reference');
     const user = authContext.authState.userInfo;

     const [redirectOnLogin, setRedirectOnLogin] = useState(false)

          // const transaction = '6e5c7ad7-0c2c-4e82-a3c8-6509a489f420';
          // const reference = '1601935966';
     
     const values = {reference,transaction, user};

     useEffect(()=>{
          const getStatusData = async() => {
              if(reference)
                    try {
                         const { data } = await fetchContext.authAxios.post(
                         'store', values
                         );
                         setStatus(data);                  

                         localStorage.removeItem('cartItems');
                         localStorage.removeItem('deliveryFee');  
                         localStorage.removeItem('deliveryPlace');
                         localStorage.removeItem('discount');  
                         localStorage.removeItem('destinationDetails'); 
                         
                         localStorage.removeItem('agreed'); 
                    
                    if(data.order._id)
                    {
                         setOrder('completed');
                         setTimeout(()=>{setRedirectOnLogin(true)}, 2000)
                    }
                    
                    } catch (err) {
                         console.log(err);
                         
                         console.log(err.message);
                         
                  }
              
          }
          getStatusData()  
         
      },[]);
      
     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Order</title>
                <link rel="canonical" href="https://larcossa.com/order" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
           {redirectOnLogin && <Redirect to='/thankyou'/>}
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-32 ' >

                <div className='bg-white rounded-md shadow mt-20 py-10 lg:px-16 '>

                    <div className='w-full py-20 px-10'>
                         
                         <div className='text-green-600 flex center items-center'>
                              <FontAwesomeIcon icon={faCheck} className='text-6xl '/>
                              <span className='px-5 text-4xl'>Order Completed</span>
                         </div>
                    </div>


                </div>

          </div>
      
        </>
        );
  }


export default Order;
