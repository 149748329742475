import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Link, useLocation } from 'react-router-dom';
import {formatCurrency, formatCurrency2} from './../util'


const MyOrders = () => {
     const location = useLocation();
     const {state} = location;
     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - My Orders</title>
                <link rel="canonical" href="https://larcossa.com" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
           <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 '
          >
                <div className='py-10'>
                   <div className='py-5 px-5 lg:px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light py-3'>My Orders</h2>
                   </div>
                    <div className='bg-white text-red-800 rounded shadow-md py-5 flex flex-wrap justify-center'>
                         <table className="table-auto hidden lg:block">
                              <thead className='text-dark1'>
                              <tr>
                                   <th className="px-4 py-3">Order Reference</th>
                                   <th className="px-4 py-3">Delivery Place</th>
                                   <th className="px-4 py-3">Destination Details</th>
                                   <th className="px-4 py-3">Order Items(Product, Quantity, Price)</th>
                                   <th className="px-4 py-3">Delivery Fee</th>
                                   <th className="px-4 py-3">Total</th>
                              </tr>
                              </thead>
                              <tbody>
                              {state.map((order, index)=>(
                                   <tr key={order._id}>
                                        <td className="border px-4 py-3">{order.reference}</td>
                                        <td className="border px-4 py-3">{order.deliveryPlace}</td>
                                        <td className="border px-4 py-3">{order.destinationDetails}</td>
                                        <td className="border px-4 py-3">{order.items.map(item=>(
                                             <div className='flex flex-row justify-between'>
                                            
                                             <span className='px-3'>{item.quantity}</span>
                                             <span className='px-3'>{item.name}</span> 
                                             <span className='px-3'>Ksh. {formatCurrency2(item.unitPrice)}</span> 
                                             </div>
                                        ))}</td>
                                        <td className="border px-4 py-3">{order.deliveryFee}</td>
                                        <td className="border px-4 py-3">Ksh. {formatCurrency2(order.total)}</td>
                                   </tr>
                              ))}
                              </tbody>
                         </table>
                         {
                              state.map((order, index)=>(
                                   <div className='flex flex-col w-full lg:hidden border-pinkWhite border rounded m-2'>
                                        <div className=' p-2 border border-pinkWhite ' >
                                             <div className='w-full'>
                                                  <span className='text-dark1'>Reference No: </span> {order.reference}
                                             </div>
                                             <div className=' w-full'>
                                                  
                                             </div>
                                        </div>
                                        <div className=' p-2 border border-pinkWhite '>
                                             <div className=' w-full'>
                                                  <span className='text-dark1'>Delivery Place: </span>{order.deliveryPlace}
                                             </div>
                                             <div className=' w-full'>
                                                  <span className='text-dark1'>Delivery Fee: </span>{order.deliveryFee}
                                             </div>
                                             <div className=' w-full'>
                                                  <span className='text-dark1'>Destination Details: </span>{order.destinationDetails}
                                             </div>
                                        </div>
                                        <div className=' p-2 border border-pinkWhite'>
                                             <div className=''>
                                                  <span className='text-dark1'>Order Items</span><br/>
                                                  {order.items.map(item=>(
                                                  <div className='w-full flex justify-between'>
                                                  
                                                  <span className='px-3'>{item.quantity}</span>
                                                  <span className='px-3'>{item.name}</span>  
                                                  <span className='px-3'>Ksh. {formatCurrency2(item.unitPrice)}</span> 
                                                  </div>
                                        ))}
                                             </div>
                                        </div>
                                        <div className=' p-2 border border-pinkWhite'>
                                             <div className=' float-right'>
                                                  <span className='text-dark1 px-'>TOTAL: </span> <span className='pr-3'>{formatCurrency2(order.total)}</span>
                                             </div>                             
                                        </div>
                                   </div>
                              )

                              )
                         }
                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default MyOrders;
