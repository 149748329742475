import React from 'react';

import './cart-item.styles.scss';

const CartItem = ({ item: { image, unitPrice, name, quantity, discountAmount } }) => (
  <div className='cart-item'>
    <img src={image} alt='item' />
    <div className='item-details'>
      <span className='name text-dark1'>{name}</span>
      <span className='price'>
        {quantity} x Ksh{discountAmount?unitPrice-discountAmount:unitPrice}
      </span>
    </div>
  </div>
);

export default CartItem;
