import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Link, useLocation } from 'react-router-dom';
import MakeupOrderForm from './../components/MakeupOrderForm';
import FormSuccess from '../components/FormSuccess';
import FormError from '../components/FormError';
import emailjs from 'emailjs-com';

const MakeupOrder = () => {
     const location = useLocation();
     const {state:{title, image, service}} = location;
     const [successMessage, setSuccessMessage] = useState();
     const [errorMessage, setErrorMessage] = useState();

     const onSubmit = (values, resetForm) => {
          const message = 'I would like to order the '+values.item+' service. Get in touch thanks';

          emailjs.send('larcossapp','template_AgONpr6f', {message_html: message, from_name: values.name,reply_to: values.email, to_name: 'Larcossa', mobile: values.mobile}, 'user_20HQM9vLrkL9CYpK1quKT')
          .then((response) => {
               console.log(response)
               setSuccessMessage('Order form Sent Successfully');
               setErrorMessage(null);
               resetForm();
          }, (err) => {
               setErrorMessage(err.message);
          });

        };

     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Makeup Order</title>
                <link rel="canonical" href="https://larcossa.com/makeup-services" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10 '>
                   <div className='py-5 px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light py-3'>
                     <span className='font-greatVibes'>{title} </span> </h2>
                   </div>
                    <div className='flex flex-wrap justify-center'>
                         <div className='w-full lg:w-1/2 px-2 lg:px-10 pb-4'>
                              <img src={image} className='float-right rounded-lg shadow w-full lg:w-10/12'/>
                         </div> 
                         <div className='w-full lg:w-1/2 px-2 lg:px-10 text-red-900'>
                              {successMessage && (
                              <FormSuccess text={successMessage} />
                              )}
                              {errorMessage && <FormError text={errorMessage} />}
                              <MakeupOrderForm onSubmit={onSubmit} item={title}/>                              
                         </div>
                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default MakeupOrder;
