import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import {publicFetch} from './../util/fetch';

const Makeup = () => {
  const [makeup, setMakeup] = useState([]);
     
  useEffect(()=>{
      const getMakeupData = async() => {
          try {
            const { data } = await publicFetch.get(
              'makeup'
            );
			setMakeup(data);
			console.log(data)
          } catch (err) {
            console.log(err);
          }
      };
	  getMakeupData();
	  
  },[]);

     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Makeup Services</title>
                <link rel="canonical" href="https://larcossa.com/makeup-services" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10'>
                   <div className='py-5 px-5 lg:px-10'>
                     <h2 className='text-red-800 text-4xl md:text-5xl lg:text-6xl font-light font-greatVibes py-3'>Makeup Artistry</h2>
                     <p className='text-red-800 w-full lg:w-4/5'>
                     Let our makeup artists trained in the art highlight your undeniable beauty LarcQueen! Our fascination for different types of makeup will have you covered for every occasion.
                     </p>
                   </div>
                    <div className='flex flex-wrap justify-center'>
                         {
							 makeup.map(service=>(
								<div className='flex flex-wrap justify-center items-center py-10'>
									<div className='w-full lg:w-1/2 px-5 lg:px-10'>
										<img src={service.image} className='float-right rounded-lg shadow w-full lg:w-10/12' />
									</div> 
									<div className='w-full lg:w-1/2 px-5 lg:px-10 text-red-900'>
										<h2 className='text-red-800 pt-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-light font-greatVibes'>
											{service.title}
										</h2>
										<p className='pb-5 sm:pb-8 md:pb-10 lg:pb-12 pt-2'>
											{service.service}
										</p>

										<Link to={{pathname:'/order-makeup-service', state:service}} className='bg-pinkDark mt-10 sm:mt-12 md:mt-16 lg:mt-20 py-3 px-8 rounded-full shadow-none hover:bg-pinkOther'>
										ORDER SERVICE</Link>
										
									</div>
								</div>
							 ))
						 }
                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default Makeup;
