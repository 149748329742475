import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';

const MakeupSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  email: Yup.string().email().required('Email is not complete'),
  mobile: Yup.number().required('Mobile Number is required')
});

const MakeupForm = ({ onSubmit, item }) => {

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        mobile: ''
      }}
      onSubmit={async (values, { resetForm }) =>{
          values.item = item
          onSubmit(values, resetForm)
        }
      }
      validationSchema={MakeupSchema}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <div className="flex flex-wrap justify-between bg-white rounded-md shadow px-3 lg:px-10 py-10">
            <h2 className="w-full px-2 py-2">Please complete this ORDER form and submit it.</h2>
            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Name" />
              </div>
              <FormInput
                ariaLabel="Your Name"
                name="name"
                type="text"
                placeholder="Your Name"
              />
            </div>

            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Email" />
              </div>
              <FormInput
                ariaLabel="Your Email"
                name="email"
                type="text"
                placeholder="Your Email"
              />
            </div>

            <div className="w-full px-2 py-3">
              <div className="mb-1">
                <Label text="Your Mobile Number" />
              </div>
              <FormInput
                ariaLabel="Your Mobile Number"
                name="mobile"
                type="text"
                placeholder="Your Mobile Number"
              />
            </div>

          </div>
          <div className="flex">
            <div className="w-full sm:w-1/2 font-charm tracking-widest font-bold text-xl mt-6">
              <GradientButton type="submit" text="send it" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MakeupForm;
