import React, {useContext, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {publicFetch} from './../util/fetch';
import { BiEnvelope, BiMobile } from 'react-icons/bi';
import ResetPassForm from './../components/ResetPassForm';
import emailjs from 'emailjs-com';
import Card from '../components/common/Card';
import Hyperlink from './../components/common/Hyperlink';
import Label from './../components/common/Label';
import FormInput from './../components/FormInput';
import FormSuccess from './../components/FormSuccess';
import FormError from './../components/FormError';
import GradientBar from './../components/common/GradientBar';
import GradientButton from '../components/common/GradientButton';
import logo from './../images/logo.png';
import {useLocation, Redirect, useHistory} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

const ResetPass = () => {
    const [email, setEmail] = useState([]);
	const [successMessage, setSuccessMessage] = useState();
	const [errorMessage, setErrorMessage] = useState();
	const location = useLocation();
	const query = new URLSearchParams(location.search)
    const code = query.get('edoctes');
    const [redirectOnLogin, setRedirectOnLogin] = useState(false);

	const onSubmit = (values) => {

		const getPass = async() => {
			
			try {
				const { data } = await publicFetch.post('reset', values);

				setSuccessMessage(data.message)
            	setTimeout(()=>{setSuccessMessage(null)}, 3000);
				setTimeout(()=>{setRedirectOnLogin(true)}, 4000); 
			} catch (err) {
				console.log(err);
				const { data } = err.response;
				setErrorMessage(data.message);	
				
			}
		
		}

		getPass();

	};

    return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa -Reset Password </title>
                <link rel="canonical" href="https://larcossa.com/" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
		   {redirectOnLogin && <Redirect to='/login'/>}
	     <section className="w-full sm:w-1/2 h-screen m-auto p-8 sm:pt-10">
			<GradientBar />
			<Card>
			<div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-md w-full">
				<div>
				<div className="w-64 m-auto mb-6">
					<img src={logo} alt="Logo" />
				</div>
				<h2 className="mb-2 text-center text-xl md:text-2xl lg:text-3xl leading-9 font-bold text-pink-900">
					Reset Password
				</h2> 
					{successMessage && (
					<FormSuccess text={successMessage} />
					)}
					{errorMessage && <FormError text={errorMessage} />}

					<ResetPassForm onSubmit={onSubmit} code={code}/>

				</div>

				</div>
			</div>
			</Card>

      </section>







      
         
      
        </>
        );
  }


export default ResetPass;
