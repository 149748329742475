import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';

const ForgotPsssSchema = Yup.object().shape({
  pass: Yup.string().required('Enter new Password')
});

const ResetPassForm = ({ onSubmit, code }) => {

  return (
    <Formik
      initialValues={{
        pass: '',
      }}
      onSubmit={async (values) =>{
          values.code=code
          onSubmit(values)
        }
      }
      validationSchema={ForgotPsssSchema}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <div className="w-full flex flex-wrap justify-between px-4 py-4 md:px-8 lg:px-10 lg:py-10">
            <h2 className="w-full px-2 py-2 text-red-800">
            Please enter your new password below.</h2>
            
              <div className=' w-full'>
                <div className="mb-1 w-full ">
                  <Label text="New Password" />
                </div>
                <FormInput
                  ariaLabel="Password"
                  name="pass"
                  type="password"
                  placeholder="New Password"
                />
              </div>

          </div>
          <div className="flex">
            <div className="w-full sm:w-1/2 mt-6 text-xl">
              <GradientButton type="submit" text="Submit" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassForm;
