import React, {useState, useContext} from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Label from './common/Label';
import FormInput from './FormInput';
import GradientButton from './common/GradientButton';
import axios from 'axios';

const SlideSchema = Yup.object().shape({
  image: Yup.string().required('Image is required')
});

const SlideForm = ({ onSubmit, categories }) => {
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState(null);

  return (
    <Formik
      initialValues={{
        caption: '',
        image: ''
      }}
      onSubmit={async (values, { resetForm }) =>{
          const formData = new FormData()
          formData.append('upload_preset', 'larcossa')
          formData.append('file', image)
          
          await axios.post('https://api.cloudinary.com/v1_1/dhbqttdlc/image/upload', formData)
          .then(res=> {
			  values.image=res.data.secure_url
			  setPhoto(null)
			  })
          .catch(err=>console.log(err))

          onSubmit(values, resetForm)
        }
      }
      validationSchema={SlideSchema}
      validateOnBlur={false}
    >
      {(formProps) => (
        <Form className='lg:px-16'>                
            <div className='py-2'>
              <div className="w-full  lg:px-2 py-1">
                <div className="mb-1">
                  <Label text="Caption" />
                </div>
                <Field
                  className="border border-gray-300 rounded lg:p-1 w-full h-20 mb-2"
                  component="textarea"
                  name="caption"
                  type="text"
                  placeholder="Image Caption"
                />
              </div>
              <div className="w-full  lg:px-2 py-1 pt-2">
                <div className="mb-1">
                  <Label text="Image (Hint: 1920px X 1200px)" />
                </div>
                  <input
                  className='w-full'
                    aria-label="Image"
                    name="image"
                    type="file"
                    placeholder="Image"
                    onChange={(e)=>{
                      formProps.setFieldValue('image', e.target.files[0].name)	
                              setPhoto(URL.createObjectURL(e.target.files[0]))
                      setImage(e.target.files[0])
                      }}
                          />
                <div className="w-full lg:w-1/2 py-2" >
                  <img className="rounded-lg shadow-lg"  src={photo && photo}></img>
                </div>  
            </div>
              </div>
              

              <GradientButton text="Save" type="submit" />
            </Form>
      )}
    </Formik>
  );
};

export default SlideForm;
