import React, {useContext,useEffect,useState} from 'react';
import PageTitle from '../components/common/PageTitle';
import { FetchContext } from '../context/FetchContext';
import Card from '../components/common/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {useLocation} from 'react-router-dom'

const CommentDetail = ({ comment, onDelete }) => (
    <div>
      <div className="flex w-full">
        <div className='b w-full py-2 flex flex-row justify-between'>
          <p className="w-full">
            {comment.name} <span className='italic text-gray-700 text-sm pl-2'>{comment.comment}</span>
          </p>
          <div className='text-pinkOther px-2'>
              <FontAwesomeIcon icon={faTrashAlt} className='text-xl hover:text-red-600'  onClick={()=> onDelete(comment)}/>
          </div>
        </div>
      </div>
    </div>
  );

const Comments =()=>{
    const location = useLocation();
    const fetchContext = useContext(FetchContext);    
    const [commentList, setCommentList] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    console.log(location)

    useEffect(()=>{
        const getComments = async () => {
          const id = location.state._id;
            try {
              const { data } = await fetchContext.authAxios.get(
                `comments/${id}`
              );
              setCommentList(data);
            } catch (err) {
              console.log(err);
            }
          };
        getComments();
    }, [fetchContext])
    console.log(commentList)

    const onDelete = async comment => {

      try {
        if (
          window.confirm(
         'Are you sure you want to delete this comment?'
          )
        ) {
          const {data} = await fetchContext.authAxios.delete(
         `comments/${comment._id}`
        );
        setCommentList(
         commentList.filter(
           comment => comment._id !== data.deletedComment._id
           )
         );
        setSuccessMessage(data.message);
        }
      } catch (err) {
        const { data } = err.response;
        setErrorMessage(data.message);
      }
       };

    return(
        <>
            <PageTitle title="Comment" />
            {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}
            <Card>
              <div className="flex flex-col">
                  {commentList.length &&
                  commentList.map(comment => (
                      <div className="mx-1 px-3">
                          <CommentDetail comment={comment} onDelete={onDelete}/>
                      </div>
                  ))}
              </div>
            </Card>
        </>
    )

}

export default Comments