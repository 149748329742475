import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { publicFetch } from './../util/fetch';
import CollectionItem from './../components/collection-item/collection-item.component';

const ShopItemContainer = ({ children }) => (
  <div
    className="rounded flex flex-col px-10 lg:flex-row "
    syle={{ backgourndColor: '#fff3f3' }}
  >
    {children}
  </div>
);
const Shop = () => {
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const getCollectionsData = async () => {
      try {
        const { data } = await publicFetch.get('collections');
        setCollections(data);
      } catch (err) {
        console.log(err);
      }
    };

    getCollectionsData();

    const getCollectionsDatag = async () => {
      try {
        const { data } = await publicFetch.get('collectionsg');
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    getCollectionsDatag();
  }, []);

  const formatItems = () => {
    let finalArr = [],
      columnItems = [];

    collections &&
      collections.forEach((collection, i) => {
        columnItems.push(
          <div
            key={collection._id}
            className="bg-white shadow-md rounded p-6 mb-5 mr-2 w-full  lg:w-1/4 "
          >
            <CollectionItem item={collection} />
          </div>
        );
        if ((i + 1) % 4 === 0) {
          finalArr.push(<ShopItemContainer>{columnItems}</ShopItemContainer>);
          columnItems = [];
        } else if (i === collections.length - 1) {
          finalArr.push(<ShopItemContainer>{columnItems}</ShopItemContainer>);
          columnItems = [];
        }
      });
    return finalArr;
  };

  if (collections) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Larcossa - Shop</title>
          <link rel="canonical" href="https://larcossa.com/shop" />
          <meta name="description" content="At Larcossa Cosmetics ..." />
        </Helmet>

        <div className="bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ">
          <div className="py-10">
            <div className="py-5 px-10 text-center">
              <h2 className="text-red-800 text-4xl md:text-5xl lg:text-6xl font-light font-greatVibes py-3">
                Liquid Matte
              </h2>
              <p className="text-red-800 w-full py-2">
                A bold formula of vegan liquid lipstick that dries down to a
                lightweight matte giving you a long-lasting, smudge-proof,
                intense colour finish.
              </p>
            </div>
            {formatItems()}
          </div>
        </div>
      </>
    );
  }
};

export default Shop;
