import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import PageTitle from '../components/common/PageTitle';
import { FetchContext } from '../context/FetchContext';
import Card from '../components/common/Card';
import defaultAvatar from './../images/defaultAvatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
const UserDetailLabel = ({ text }) => (
  <p className="mt-2 uppercase font-bold text-gray-500 text-xs">
    {text}
  </p>
);
const UserDetail = ({ user, onDelete }) => (
  <Card>
    <div className="flex flex-col lg:flex-row justify-start">
      <div className="w-10 lg:w-24">
        <img
          src={user.avatar || defaultAvatar}
          alt="avatar"
        />
      </div>

      <div className='w-full lg:w-1/4 lg:pb-5 self-end'>
        <p className="font-bold text-lg ">
          {user.firstName} {user.lastName}
        </p>
        
      </div>

      <div className='w-full flex  flex-col lg:flex-row'>
        <div className="lg:w-1/2 lg:px-8">
          <UserDetailLabel text="Email" />
            <p className="text-gray-700">
              {user.email}
            </p>
        </div>

        <div className="w-full lg:px-8 flex flex-row items-end justify-between lg:items-start lg:justify-between">
            <div className=''>
            <UserDetailLabel text="Mobile" />
            <p className="text-gray-700">
              0{user.mobile}
            </p>
            </div>
            <FontAwesomeIcon icon={faTrashAlt} className=' text-pinkDark hover:text-red-600 text-xl lg:text-3xl lg:self-center' onClick={() => onDelete(user)}/>
        </div>
        <div>
        
        </div>
      </div>

    </div>
  </Card>
);

const Users = () => {
  const fetchContext = useContext(FetchContext);
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const onDelete = async user => {

    try {
      if (
        window.confirm(
          'Are you sure you want to delete this user?'
        )
      ) {
        const {data} = await fetchContext.authAxios.delete(
          `users/${user._id}`
         );
         setUsers(
          users.filter(
            user => user._id !== data.deletedUser._id
            )
          );
         setSuccessMessage(data.message);
      }
    } catch (err) {
      const { data } = err.response;
      setErrorMessage(data.message);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          'users'
        );
        setUsers(data.users);
        console.log(data)
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, [fetchContext]);

  return (
    <>
      <PageTitle title="Users" />
      {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}

      <div className="flex flex-col">
        {!!users.length &&
          users.map(user => (
            <div className="m-2">
              {user.role==='admin'?null:<UserDetail key={user._id} user={user} onDelete={onDelete}/>}
            </div>
          ))}
      </div>
    </>
  );
};

export default Users;
