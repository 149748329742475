import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {publicFetch} from './../util/fetch';
import { Link } from 'react-router-dom';

const Blog = () => {
     const [blog, setBlog] = useState([]);
     
     useEffect(()=>{
        const getBlogData = async() => {
            try {
              const { data } = await publicFetch.get(
                'blog'
              );
              console.log(data)
              setBlog(data);	
            } catch (err) {
              console.log(err);
            }
        };
        getBlogData();
    },[]);
    console.log(blog)
     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - Blog </title>
                <link rel="canonical" href="https://larcossa.com/blog" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 ' >
                <div className='py-10 pt-20'>
                   
                    <div className='flex flex-wrap justify-center lg:px-48 font-roboto'>
                         
                      {
                        blog.map(post=>(
                        <div className="overflow-hidden rounded-lg shadow-lg bg-white" key={post._id}>
                          <Link to={{pathname:`/blog/${post._id}`, state:post }}>
                            <img alt="Placeholder" className="inline h-auto w-full" src={post.image} />
                          </Link>

                          <div className=" leading-tight px-5 py-6 lg:px-10">
                            <h1 className="text-2xl sm:text-3xl lg:text-4xl italic text-red-800 lg:pt-8">
                                <Link to={{pathname:`/blog/${post._id}`, state:post }}  className="hover:text-dark1 font-playFair" href="#">
                                    {post.title}
                                </Link>
                            </h1>
                            <p className="text-pinkDark text-sm py-3">
                                {post.date.slice(0,15)} 
                            </p>
                            <p className="text-black font-roboto hover:text-gray-700 py-5 leading-7 font-normal">
                                <Link  to={{pathname:`/blog/${post._id}`, state:post }}>{post.desc}
                                 <span className='px-2 text-red-800 hover:text-dark1'>Read more...</span>
                                </Link>
                            </p>
                            <p className="text-pinkDark py-2">
                            by Larcossa
                            </p>

                          </div>

                          

                        </div>
                        ))
                      }


                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default Blog;
