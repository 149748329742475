import React,{useContext, useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import './App.css';
import AppShell from './AppShell';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';
import MyAccount from './pages/MyAccount';
import Dashboard from './pages/Dashboard';
import FourOFour from './pages/FourOFour';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ManageShop from './pages/ManageShop';
import ManageShopItem from './pages/ManageShopItem';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Users from './pages/Users';
import DiscountAdd from './pages/DiscountAdd';
import FrontShell from './FrontShell';
import WriteUps from './pages/WriteUps';
import ManageAbout from './pages/ManageAbout';
import CartProvider from './providers/cart/cart.provider';
import CheckoutPage from './pages/checkout/checkout.component';
import Shop from './pages/Shop';
import Order from './pages/Order';
import ManagePrivacy from './pages/ManagePrivacy';
import ManageReturns from './pages/ManageReturns';
import ManageShipping from './pages/ManageShipping';
import ManageTerms from './pages/ManageTerms';
import ManageFaq from './pages/ManageFaq';
import ManageMakeup from './pages/ManageMakeup';
import ManageContact from './pages/ManageContact';
import ManageSlide from './pages/ManageHome';  
import Privacy from './pages/Privacy';
import Returns from './pages/Returns';
import Shipping from './pages/Shipping';
import Terms from './pages/Terms';
import Makeup from './pages/Makeup';
import ScrollToTop from './components/scroll.component';
import Faq from './pages/Faq';
import MakeupOrder from './pages/MakeupOrder';
import Contact from './pages/Contact'
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import ManageBlog from './pages/ManageBlog';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import ManageSub from './pages/ManageSub';
import MyOrders from './pages/MyOrders';
import Comments from './pages/Comments'
import ForgotPass from './pages/ForgotPass';
import ResetPass from './pages/ResetPass'
import ThankYou from './pages/ThankYou';
import { CartContext } from './providers/cart/cart.provider';
// import JsonUploader from './pages/data/JsonUploader'

const AuthenticatedRoute = ({children, ...rest}) =>{
  const authContext = useContext(AuthContext);
  return (
    <Route {...rest}
      render={()=>
        authContext.isAuthenticated()
        ?(<AppShell>
          {children}
        </AppShell>)
        : (<Redirect to='/' />)
        }
    />
  )
}

const AdminRoute = ({children, ...rest}) =>{
  const authContext = useContext(AuthContext);
  return (
    <Route {...rest}
      render={()=>
        authContext.isAuthenticated() && authContext.isAdmin()
        ?(<AppShell>
          {children}
        </AppShell>)
        : (<Redirect to='/' />)
        }
    />
  )
}
const MyRoute = ({children, ...rest}) =>{
  const authContext = useContext(AuthContext);
  return (
    <Route {...rest}
      render={()=>
        authContext.isAuthenticated() && authContext.isUser()
        ?(<FrontShell>
          {children}
        </FrontShell>)
        : (<Redirect to='/' />)
        }
    />
  )
}

const AppRoutes = () => {

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPass />
      </Route>
      <Route path="/reset-password">
        <ResetPass />
      </Route>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route exact path="/">
        <FrontShell>
          <Home />
        </FrontShell>
      </Route>
      <Route exact path="/about-us">
        <FrontShell>
          <AboutUs />
        </FrontShell>
      </Route>
      <Route exact path="/shop">
        <FrontShell>
          <Shop />
        </FrontShell>
      </Route>
      <Route exact path="/privacy-policy">
        <FrontShell>
          <Privacy />
        </FrontShell>
      </Route>
      <Route exact path="/return-policy">
        <FrontShell>
          <Returns/>
        </FrontShell>
      </Route>
      <Route exact path="/shipping-policy">
        <FrontShell>
          <Shipping />
        </FrontShell>
      </Route>
      <Route exact path="/terms-of-use">
        <FrontShell>
          <Terms />
        </FrontShell>
      </Route>
      <Route exact path="/faqs">
        <FrontShell>
          <Faq />
        </FrontShell>
      </Route>
      <Route exact path="/makeup-services">
        <FrontShell>
          <Makeup />
        </FrontShell>
      </Route>
      <Route exact path="/order-makeup-service">
        <FrontShell>
          <MakeupOrder />
        </FrontShell>
      </Route>
      <Route exact path="/contact">
        <FrontShell>
          <Contact />
        </FrontShell>
      </Route>
      {/* <Route exact path="/jsonu">
          <JsonUploader />
      </Route> */}
      <Route exact path="/blog">
        <FrontShell>
          <Blog />
        </FrontShell>
      </Route>
      <Route exact path="/blog/:id">
        <FrontShell>
          <BlogPost />
        </FrontShell>
      </Route>
      <Route exact path="/checkout">
        <FrontShell>
          <CheckoutPage />
        </FrontShell>
      </Route>
      <Route exact path="/order">
        <FrontShell>
          <Order />
        </FrontShell>
      </Route>
      <AdminRoute path="/dashboard" >   
        <Dashboard />
      </AdminRoute>
      <AdminRoute path="/manage-item" >   
        <ManageShopItem />
      </AdminRoute>
      
      <AdminRoute path="/manage-shop" >   
        <ManageShop />
      </AdminRoute>
      
      <AdminRoute path="/users">
        <Users />
      </AdminRoute> 
      <AdminRoute path="/manage-home">
        <ManageSlide />
      </AdminRoute>
      <AdminRoute path="/manage-write-ups">
        <WriteUps />
      </AdminRoute>
      <MyRoute path="/my-account">
        <MyAccount />
      </MyRoute>
      <MyRoute path="/thankyou">
        <ThankYou />
      </MyRoute>
      <MyRoute path="/my-orders">
        <MyOrders/>
      </MyRoute>
      <AdminRoute path="/manage-about">
        <ManageAbout />
      </AdminRoute>
      <AdminRoute path="/manage-privacy">
        <ManagePrivacy />
      </AdminRoute>
      <AdminRoute path="/manage-returns">
        <ManageReturns />
      </AdminRoute>
      <AdminRoute path="/manage-shipping">
        <ManageShipping />
      </AdminRoute>
      <AdminRoute path="/manage-terms">
        <ManageTerms />
      </AdminRoute>
      <AdminRoute path="/manage-faqs">
        <ManageFaq />
      </AdminRoute>
      <AdminRoute path="/manage-makeup">
        <ManageMakeup />
      </AdminRoute>
      <AdminRoute path="/manage-contact">
        <ManageContact />
      </AdminRoute>
      <AdminRoute path="/manage-blog">
        <ManageBlog />
      </AdminRoute>
      <AdminRoute path="/manage-sub">
        <ManageSub />
      </AdminRoute>
      <AdminRoute path="/manage-discounts">
        <DiscountAdd />
      </AdminRoute>
      <AdminRoute path="/comments">
        <Comments />
      </AdminRoute>
      <Route path="*">
        <FourOFour />
      </Route>
    </Switch>
  );
};

function App() {
  const cartContext = useContext(CartContext);

  useEffect(() => {
    ReactGA.initialize('UA-176703611-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const advancedMatching = { em: 'kariuki.vk@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init('274601963809297', advancedMatching, options);

  ReactPixel.pageView(); // For tracking page view
  //ReactPixel.track('Purchase', {currency: "Ksh", value: cartContext.subTotal}); 

  return (
    <Router>
      <CartProvider>
        <AuthProvider>
          <FetchProvider>
              <ScrollToTop />
              <div className="bg-pinkWhite">
                <AppRoutes />
              </div>        
          </FetchProvider>
        </AuthProvider>
      </CartProvider>
    </Router>
  );
}

export default App;
