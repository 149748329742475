import React, { useContext } from 'react';

import CustomButton from './../custom-button/custom-button.component';
import {CartContext} from './../../providers/cart/cart.provider';
import './collection-item.styles.scss';
import {formatCurrency2} from './../../util'

const CollectionItem = ({ item }) => {
  const { name, unitPrice, image, discountAmount, category, color, desc, soldOut } = item;
  const { addItem } = useContext(CartContext);

  return (
    <div className='collection-item '>
      <div
        className='image'
        style={{
          backgroundImage: `url(${image})`
        }}
      />
      <div className='w-full h-2/5 px-1'>
        <div className='tracking-tight font-semibold text-xl  text-red-800 pb-1'>{name}
        {soldOut?<span className='float-right text-white bg-red-600 text-xs px-3 py-1 rounded-full font-bold'>SOLD OUT</span>:null

        }</div>
        <div className='tracking-tight text-sm  text-red-700'>{color} </div>
        <div className='desc tracking-tight text-sm text-pinkDark '>{desc}</div>

        <div className='text-xl text-pinkOther py-2'>
          {discountAmount>0
          ?<span className=''><span className='pr-2 line-through text-gray-600'>Ksh {formatCurrency2(unitPrice)}</span>Ksh{formatCurrency2(unitPrice-discountAmount)}</span>
          :<span>Ksh {formatCurrency2(unitPrice)}</span>}
        </div>
      </div>
      {soldOut
      ?null
      :<CustomButton onClick={() => addItem(item)} inverted>
        Add to Bag
      </CustomButton>}
    </div>
  );
};

export default CollectionItem;
