import { parse } from "@fortawesome/fontawesome-svg-core";

export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem._id === cartItemToAdd._id
  );

  if (existingCartItem) {
    return cartItems.map(cartItem =>
      cartItem._id === cartItemToAdd._id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }

  return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
};

export const addIdToIds = (ids, idToAdd) => {
  const existingId = ids.find(
    id => id === idToAdd
  );

  if (existingId) {
    return [...ids];   
  }
  return [...ids, idToAdd ];
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem._id === cartItemToRemove._id
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter(cartItem => cartItem._id !== cartItemToRemove._id);
  }

  return cartItems.map(cartItem =>
    cartItem._id === cartItemToRemove._id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const filterItemFromCart = (cartItems, item) =>
  cartItems.filter(cartItem => cartItem._id !== item._id);

export const getCartItemsCount = cartItems =>
  cartItems.reduce(
    (accumalatedQuantity, cartItem) => accumalatedQuantity + cartItem.quantity,
    0
  );

export const getCartTotal = (cartItems, deliveryFee, discount) =>{
  const total  = cartItems.reduce(
    (accumalatedQuantity, cartItem) =>
      (accumalatedQuantity + cartItem.quantity * cartItem.unitPrice) - (cartItem.discountAmount*cartItem.quantity)
    , 0
  );

  return total + parseInt(deliveryFee) - discount

}

export const getSubTotal = (cartItems) =>{
  const subTotal  = cartItems.reduce(
    (accumalatedQuantity, cartItem) =>
      (accumalatedQuantity + cartItem.quantity * cartItem.unitPrice) - (cartItem.discountAmount*cartItem.quantity)
    , 0
  );

  return subTotal

}
