import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PageTitle from '../components/common/PageTitle';
import Card from '../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from '../context/FetchContext';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import 'react-quill/dist/quill.snow.css';
import Label from './../components/common/Label';
import FormInput from './../components/FormInput';
import {useLocation, Redirect} from 'react-router-dom'

const DiscountAdd = () => {
const fetchContext = useContext(FetchContext);
const [successMessage, setSuccessMessage] = useState();
const [errorMessage, setErrorMessage] = useState();
const [discount, setDiscount] = useState();
console.log(discount)

useEffect(() => {
  const getDiscount = async () => {
    try {
      const { data } = await fetchContext.authAxios.get(
        'discount'
      );
      setDiscount(data[0])
    } catch (err) {
      console.log(err);
    }
  };
  getDiscount();
}, [fetchContext]);

const saveDiscount = async values => {
  try {
    const { data } = await fetchContext.authAxios.patch(
      'discount',
      values
    );
    setDiscount(data[0])
    setErrorMessage(null);
    setSuccessMessage(data.message);
  } catch (err) {
    const { data } = err.response;
    setSuccessMessage(null);
    setErrorMessage(data.message);
  }
};

const saveSale = async values => {
  try {
    const { data } = await fetchContext.authAxios.patch(
      'shop',
      values
    );
    setErrorMessage(null);
    setSuccessMessage(data.message);
  } catch (err) {
    const { data } = err.response;
    setSuccessMessage(null);
    setErrorMessage(data.message);
  }
};
return (
  <>
    <PageTitle title='Discounts & Sales'  />
    <Card >
      {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}
      <div className='flex flex-col lg:flex-row'>
      <Formik
        initialValues={{
          code: '',
          amount: ''
        }}
        onSubmit ={ async (values) => {
          console.log(values)
          saveDiscount(values)
          }}
        enableReinitialize={true}
      >
        {() => (
          <Form className='w-full lg:w-1/2'>                
            <div className='py-2'>
              <div className="w-full px-2 py-3">
                <div className="mb-1">
                  <Label text="Discount Code" />
                </div>
                <FormInput
                  ariaLabel="Discount Code"
                  name="code"
                  type="text"
                  placeholder="Discount Code"
                />
              </div>
              <div className="w-full px-2 py-3">
                <div className="mb-1">
                  <Label text="Discount Amount" />
                </div>
                <FormInput
                  ariaLabel="Discount Amount"
                  name="amount"
                  type="text"
                  placeholder="Discount Amount"
                />
              </div>
            </div>
            <GradientButton text="Save Discount Info" type="submit" />
          </Form>
        )}
      </Formik>
      
      <Formik
        initialValues={{
          amount: '',
        }}
        onSubmit ={ async (values) => {
          saveSale(values)
          }}
        enableReinitialize={true}
      >
        {() => (
          <Form className='w-full lg:w-1/2 pt-10 lg:pt-0'>                
            <div className='py-2'>
              
              <div className="w-full px-2 py-3">
                <div className="mb-1">
                  <Label text="Sale Amount" />
                </div>
                <FormInput
                  ariaLabel="Sale Amount"
                  name="amount"
                  type="text"
                  placeholder="Sale Amount"
                />
              </div>
            </div>
            <GradientButton text="Save Sale Amount" type="submit" />
          </Form>
        )}
      </Formik>
      </div>
    </Card>

    <div>
      {/* <div>
        {discount.code}
      </div>
      <div>
        {discount.amount}
      </div> */}
    </div>

  </>
);
};

export default DiscountAdd;
