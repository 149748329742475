import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PageTitle from '../components/common/PageTitle';
import Card from '../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from '../context/FetchContext';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Label from './../components/common/Label';
import FormInput from './../components/FormInput';

const ManageContact = () => {
const fetchContext = useContext(FetchContext);
const [successMessage, setSuccessMessage] = useState();
const [errorMessage, setErrorMessage] = useState();
const [contact, setContact] = useState('');
const [value, setValue] = useState('');


useEffect(() => {
  const getContact = async () => {
    try {
      const { data } = await fetchContext.authAxios.get(
        'contact'
      );
      const{contact} = data[0]
      setValue(contact);
      setContact(data[0])
      
    } catch (err) {
      console.log(err);
    }
  };
  getContact();
}, [fetchContext]);

const saveContact = async contact => {
  try {
    const { data } = await fetchContext.authAxios.post(
      'contact',
      contact
    );
    setErrorMessage(null);
    setSuccessMessage(data.message);
  } catch (err) {
    const { data } = err.response;
    setSuccessMessage(null);
    setErrorMessage(data.message);
  }
};
return (
  <>
    <PageTitle title="Contact Info" />
    <Card>
      {successMessage && (
        <FormSuccess text={successMessage} />
      )}
      {errorMessage && <FormError text={errorMessage} />}
      <Formik
        initialValues={{
          email: '',
          mobile: ''
        }}
        onSubmit ={ async (values) => {
          saveContact(values)
          }}
        enableReinitialize={true}
      >
        {() => (
          <Form>                
            <div className='py-2'>
              <div className="w-full lg:w-1/2 px-2 py-3">
                <div className="mb-1">
                  <Label text="Email" />
                </div>
                <FormInput
                  ariaLabel="Email"
                  name="email"
                  type="text"
                  placeholder="Email"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2 py-3">
                <div className="mb-1">
                  <Label text="Mobile" />
                </div>
                <FormInput
                  ariaLabel="Mobile"
                  name="mobile"
                  type="text"
                  placeholder="Mobile"
                />
              </div>
            </div>
            <GradientButton text="Save" type="submit" />
          </Form>
        )}
      </Formik>
    </Card>
  </>
);
};

export default ManageContact;
