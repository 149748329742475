import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {publicFetch} from '../util/fetch';

const AboutUs = () => {
     const [about, setAbout] = useState([]);
     const [image, setImage] = useState([]);
     
     useEffect(()=>{
        const getAboutData = async() => {
            try {
              const { data } = await publicFetch.get(
                'about'
              );
              const {about, image} = data[0];
              setAbout(about);
              setImage(image);
            } catch (err) {
              console.log(err);
            }
        };
        getAboutData();
    },[]);

     return (
          < >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Larcossa - About Us</title>
                <link rel="canonical" href="https://larcossa.com/about-us" />
                <meta name="description" content="At Larcossa Cosmetics ..." />
           </Helmet>
      
          <div className='bg-pinkWhite font-roboto container mx-auto py-10 px-5 lg:px-16 lg:py-16 xl:px-20 '
          style={{backgroundImage: 'url("/bg.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat:'no-repeat', width: '100vw',
        }} >
                <div className='py-10 flex flex-col'>
                   <div className='w-full py-2 lg:py-5 px-4 lg:px-10 '>
                     <h2 className='text-dark2 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-light py-3 float-right'>Be a Bold force of Beauty</h2>
                   </div>
                    <div className='w-full flex justify-center clear-fix'>
                         <div className='hidden md:w-1/2 md:block lg:w-1/2 px-10'>
                         </div> 
                         <div className='w-full md:w-1/2 px-4 lg:px-10 text-dark1' >
                              
                              <div dangerouslySetInnerHTML={{__html: about}} />
                         </div>
                    </div>
                </div>

          </div>
      
        </>
        );
  }


export default AboutUs;
