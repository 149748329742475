import React, {
    useContext,
    useState,
    useEffect
  } from 'react';
import * as Yup from 'yup';
import PageTitle from '../components/common/PageTitle';
import Card from '../components/common/Card';
import GradientButton from '../components/common/GradientButton';
import { Formik, Form, Field } from 'formik';
import { FetchContext } from '../context/FetchContext';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import ReactQuill from 'react-quill';

import Label from './../components/common/Label';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const AboutImageSchema = Yup.object().shape({
  });
  
const ManageAbout = () => {
  const fetchContext = useContext(FetchContext);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [about, setAbout] = useState('');
  const [value, setValue] = useState('');
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    const getAbout = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          'about'
        );
        const{about, image} = data[0]
        setValue(about);
        setAbout(data[0])
        setPhoto(image)
        
      } catch (err) {
        console.log(err);
      }
    };
    getAbout();
  }, [fetchContext]);

  const saveAbout = async about => {
    try {
      const { data } = await fetchContext.authAxios.patch(
        'about',
        about
      );
      console.log(data)
      setErrorMessage(null);
      setSuccessMessage(data.message);
    } catch (err) {
      const { data } = err.response;
      setSuccessMessage(null);
      setErrorMessage(data.message);
    }
  };
  return (
    <>
      <PageTitle title="About Us" />
      <Card>
        {successMessage && (
          <FormSuccess text={successMessage} />
        )}
        {errorMessage && <FormError text={errorMessage} />}
        <Formik
          initialValues={{
            image:''
          }}
          onSubmit ={ async (values) => {
      
            if(values.image===''){
              values.image = photo;

            }else{
              const formData = new FormData()
              formData.append('upload_preset', 'larcossa')
              formData.append('file', image)

              await axios.post('https://api.cloudinary.com/v1_1/dhbqttdlc/image/upload', formData)
              .then(res=> {
              values.image= res.data.secure_url
              setPhoto(null)
              })
              .catch(err=>console.log(err))
            }

            values.id = about._id
            values.about= value
            console.log(values)

            saveAbout(values)
            }}
          validationSchema={AboutImageSchema}
          enableReinitialize={true}
        >
          {(formProps) => (
            <Form>                
              <div className='py-2'>
              
                <div className="w-full lg:px-2 py-1">
               
                  <ReactQuill theme="snow" value={value} onChange={setValue} className=' px-2 pb-4 lg:px-8 '/> 
                </div>
              </div>
              
              <div className='m-2'>
                <GradientButton text="Save" type="submit" />
              </div>
              
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};
  
export default ManageAbout;
  